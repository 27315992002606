import React, { createContext, useContext, useEffect, useState } from 'react';
import { AudioPlayer, AudioCategory } from './AudioPlayer';

// Определение типов для аудио-контекста
export interface AudioContextState {
  isMuted: boolean;
  masterVolume: number;
  categoryVolumes: Record<AudioCategory, number>;
  preloadedSounds: string[];
}

export interface AudioContextValue extends AudioContextState {
  toggleMute: () => void;
  setMasterVolume: (volume: number) => void;
  setCategoryVolume: (category: AudioCategory, volume: number) => void;
  muteCategory: (category: AudioCategory, muted: boolean) => void;
  preloadSound: (id: string, url: string, category?: AudioCategory) => void;
  playSound: (id: string) => void;
}

// Аудио-эффекты для интерфейса
export const UI_SOUNDS = {
  CLICK: {
    id: 'ui-click',
    url: '/sounds/ui/click.mp3',
  },
  SELECT: {
    id: 'ui-select',
    url: '/sounds/ui/select.mp3',
  },
  TOGGLE: {
    id: 'ui-toggle',
    url: '/sounds/ui/toggle.mp3',
  },
  MONETA: {
    id: 'ui-moneta',
    url: '/sounds/ui/moneta.mp3',
  },
  PREFERENCE: {
    id: 'ui-preference',
    url: '/sounds/ui/preference.mp3',
  },
  INTEREST: {
    id: 'ui-interest',
    url: '/sounds/ui/interest.mp3',
  },
  HOMEPAGE: {
    id: 'ui-homepage',
    url: '/sounds/ui/homeppage.mp3',
  },
};

const defaultContextValue: AudioContextState = {
  isMuted: false,
  masterVolume: 1,
  categoryVolumes: {
    music: 0.7,
    sfx: 1.0,
    voice: 1.0,
    ambient: 0.5,
  },
  preloadedSounds: [],
};

export const AudioContext = createContext<AudioContextValue | null>(null);

export const AudioProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, setState] = useState<AudioContextState>(defaultContextValue);
  const audioPlayer = AudioPlayer.getInstance();

  // Предзагрузить стандартные звуки UI при инициализации
  useEffect(() => {
    // Получаем сохраненные настройки звука из localStorage
    const savedSettings = localStorage.getItem('audioSettings');
    if (savedSettings) {
      try {
        const parsedSettings = JSON.parse(savedSettings);
        setState(currentState => ({
          ...currentState,
          isMuted: parsedSettings.isMuted ?? currentState.isMuted,
          masterVolume: parsedSettings.masterVolume ?? currentState.masterVolume,
          categoryVolumes: {
            ...currentState.categoryVolumes,
            ...parsedSettings.categoryVolumes,
          },
        }));
        
        // Применяем загруженные настройки
        audioPlayer.mute(parsedSettings.isMuted ?? state.isMuted);
        audioPlayer.setMasterVolume(parsedSettings.masterVolume ?? state.masterVolume);
        
        if (parsedSettings.categoryVolumes) {
          Object.entries(parsedSettings.categoryVolumes).forEach(([category, volume]) => {
            audioPlayer.setCategoryVolume(category as AudioCategory, volume as number);
          });
        }
      } catch (error) {
        console.warn('Ошибка при загрузке аудио-настроек:', error);
      }
    }
    
    // Предзагружаем стандартные звуки UI
    const uiSoundsToPreload = [UI_SOUNDS.CLICK, UI_SOUNDS.SELECT, UI_SOUNDS.TOGGLE, UI_SOUNDS.MONETA, UI_SOUNDS.PREFERENCE, UI_SOUNDS.INTEREST, UI_SOUNDS.HOMEPAGE];
    uiSoundsToPreload.forEach(sound => {
      preloadSound(sound.id, sound.url, 'sfx');
    });
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Сохраняем настройки звука при их изменении
  useEffect(() => {
    localStorage.setItem('audioSettings', JSON.stringify({
      isMuted: state.isMuted,
      masterVolume: state.masterVolume,
      categoryVolumes: state.categoryVolumes,
    }));
  }, [state.isMuted, state.masterVolume, state.categoryVolumes]);

  // Переключение звука
  const toggleMute = () => {
    const newMuteState = !state.isMuted;
    audioPlayer.mute(newMuteState);
    setState(currentState => ({ ...currentState, isMuted: newMuteState }));
  };

  // Установка общей громкости
  const setMasterVolume = (volume: number) => {
    const normalizedVolume = Math.max(0, Math.min(1, volume));
    audioPlayer.setMasterVolume(normalizedVolume);
    setState(currentState => ({ ...currentState, masterVolume: normalizedVolume }));
  };

  // Установка громкости категории
  const setCategoryVolume = (category: AudioCategory, volume: number) => {
    const normalizedVolume = Math.max(0, Math.min(1, volume));
    audioPlayer.setCategoryVolume(category, normalizedVolume);
    setState(currentState => ({
      ...currentState,
      categoryVolumes: {
        ...currentState.categoryVolumes,
        [category]: normalizedVolume,
      },
    }));
  };

  // Отключение звука для категории
  const muteCategory = (category: AudioCategory, muted: boolean) => {
    audioPlayer.muteCategory(category, muted);
  };

  // Предзагрузка звука
  const preloadSound = (id: string, url: string, category: AudioCategory = 'sfx') => {
    if (!state.preloadedSounds.includes(id)) {
      audioPlayer.load({ id, url, preload: true }, category);
      setState(currentState => ({
        ...currentState,
        preloadedSounds: [...currentState.preloadedSounds, id],
      }));
    }
  };

  // Воспроизведение звука
  const playSound = (id: string) => {
    if (!state.isMuted && state.preloadedSounds.includes(id)) {
      audioPlayer.play(id);
    }
  };

  const value: AudioContextValue = {
    ...state,
    toggleMute,
    setMasterVolume,
    setCategoryVolume,
    muteCategory,
    preloadSound,
    playSound,
  };

  return <AudioContext.Provider value={value}>{children}</AudioContext.Provider>;
};

// Хук для использования аудио-контекста
export function useAudioContext() {
  const context = useContext(AudioContext);
  if (!context) {
    throw new Error('useAudioContext must be used within an AudioProvider');
  }
  return context;
}

// Хук для воспроизведения звуков UI
export function useUiSound() {
  const { playSound, isMuted } = useAudioContext();
  
  return {
    playClickSound: () => !isMuted && playSound(UI_SOUNDS.CLICK.id),
    playSelectSound: () => !isMuted && playSound(UI_SOUNDS.SELECT.id),
    playToggleSound: () => !isMuted && playSound(UI_SOUNDS.TOGGLE.id),
    playMonetaSound: () => !isMuted && playSound(UI_SOUNDS.MONETA.id),
    playPreferenceSound: () => !isMuted && playSound(UI_SOUNDS.PREFERENCE.id),
    playInterestSound: () => !isMuted && playSound(UI_SOUNDS.INTEREST.id),
    playHomepageSound: () => !isMuted && playSound(UI_SOUNDS.HOMEPAGE.id),
  };
} 