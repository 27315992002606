import { PropsWithChildren, useEffect, useState } from 'react';
import {
  useInitializeDb,
  checkAndMigrateDatabase,
} from '../lib/useInitializeDb';
import { dbStatus } from '@/shared/lib/db/gift-database';
import { Loading } from '@/shared/ui/loading';
import { ErrorMessage } from '@/shared/ui/error-message';

export interface StoreProviderProps extends PropsWithChildren {}

/**
 * Провайдер для управления глобальным состоянием приложения
 * и инициализации базы данных
 */
export function StoreProvider({ children }: StoreProviderProps) {
  const [migrationStatus, setMigrationStatus] = useState<
    'pending' | 'success' | 'error'
  >('pending');

  // Инициализируем базу данных
  const { isLoading, isSuccess, error, dbUnavailable } = useInitializeDb();

  // Выполняем миграцию БД при монтировании
  useEffect(() => {
    const runMigration = async () => {
      try {
        if (dbUnavailable) {
          console.log('База данных недоступна, пропускаем миграцию');
          setMigrationStatus('success');
          return;
        }
        
        const result = await checkAndMigrateDatabase();
        console.log(`Проверка миграции: текущая версия ${result.currentVersion}, последняя ${result.latestVersion}`);
        setMigrationStatus('success');
      } catch (e) {
        console.error('Ошибка миграции БД:', e);
        setMigrationStatus('error');
      }
    };
    
    if (isSuccess) {
      runMigration();
    }
  }, [isSuccess, dbUnavailable]);

  if (isLoading) {
    return (
      <Loading
        title="Загрузка данных..."
        subtitle="Пожалуйста, подождите, идет инициализация приложения"
      />
    );
  }

  if (error && !dbStatus.useLocalStorageFallback) {
    return (
      <ErrorMessage
        title="Ошибка базы данных"
        message={`Произошла ошибка при инициализации базы данных. Пожалуйста, обновите страницу или очистите кэш браузера.`}
        error={error}
      />
    );
  }

  if (migrationStatus === 'pending') {
    return (
      <Loading
        title="Подготовка данных..."
        subtitle="Пожалуйста, подождите, идет обновление структуры данных"
      />
    );
  }

  if (migrationStatus === 'error') {
    return (
      <ErrorMessage
        title="Ошибка миграции"
        message="Произошла ошибка при обновлении структуры данных. Пожалуйста, обновите страницу или очистите кэш браузера."
      />
    );
  }

  if (dbStatus.useLocalStorageFallback && error) {
    console.warn('Используется резервное хранилище (localStorage).');
  }

  return <>{children}</>;
} 