import { create } from 'zustand';
import {
  CompanionCharacter,
  CompanionExpression,
  CompanionAnimation,
  CompanionEvent,
  CompanionEventType,
  CompanionPhrase,
  DEFAULT_COMPANION,
  COMPANION_PHRASES,
  COMPANION_EVENTS,
  STEP_TO_PHRASES_MAP,
  IDLE_TIMEOUT
} from '@/widgets/companion-character/model';

interface CompanionState {
  // Основное состояние
  currentCharacter: CompanionCharacter | null;
  isVisible: boolean;
  isSpeaking: boolean;
  isMuted: boolean;
  currentExpression: CompanionExpression;
  currentAnimation: CompanionAnimation | null;
  currentPhrase: CompanionPhrase | null;
  isAnimating: boolean;
  lastInteractionTime: number;
  idleTimeoutId: NodeJS.Timeout | null;
  
  // Очередь событий для обработки
  eventQueue: Array<CompanionEvent>;
  
  // История фраз (для избегания повторов)
  phrasesHistory: Array<string>;
  
  // Настройки
  autoHideTimeout: number;
  disableIdleAnimation: boolean;
}

interface CompanionActions {
  // Управление персонажем
  setCharacter: (character: CompanionCharacter) => void;
  setVisibility: (isVisible: boolean) => void;
  setSpeaking: (speaking: boolean) => void;
  setMuted: (muted: boolean) => void;
  setExpression: (expression: CompanionExpression) => void;
  playAnimation: (animation: CompanionAnimation) => void;
  stopAnimation: () => void;
  
  // Взаимодействие с фразами
  showPhrase: (phrase: CompanionPhrase | string) => void;
  showRandomPhraseByTag: (tag: string) => void;
  showRandomPhraseByStep: (step: string) => void;
  clearPhrase: () => void;
  
  // Управление событиями
  handleEvent: (eventType: CompanionEventType, params?: Record<string, any>) => void;
  processEventQueue: () => void;
  
  // Обработка взаимодействия
  updateInteractionTime: () => void;
  
  // Настройки
  setAutoHideTimeout: (timeout: number) => void;
  setDisableIdleAnimation: (disable: boolean) => void;
  setupIdleTimer: () => void;
  clearIdleTimer: () => void;
}

// Начальное состояние
const initialState: CompanionState = {
  currentCharacter: DEFAULT_COMPANION,
  isVisible: true,
  isSpeaking: true,
  isMuted: false,
  currentExpression: 'happy',
  currentAnimation: 'wave',
  currentPhrase: {
    id: 'greeting_initial',
    text: 'Привет! Я Фея Дарина, и я помогу тебе выбрать идеальный подарок!',
    expression: 'happy',
    animation: 'wave',
    tags: ['greeting', 'introduction', 'welcome']
  },
  isAnimating: true,
  lastInteractionTime: Date.now(),
  eventQueue: [],
  phrasesHistory: [],
  autoHideTimeout: IDLE_TIMEOUT,
  disableIdleAnimation: false,
  idleTimeoutId: null
};

// Проверяем, нужно ли сбросить данные по компаньону
// Это необходимо для решения проблем с некорректными данными во всех браузерах
try {
  // Очищаем позицию компаньона при старте приложения
  localStorage.removeItem('companionPosition');
} catch (e) {
  console.error('Ошибка при сбросе позиции компаньона:', e);
}

export const useCompanionStore = create<CompanionState & CompanionActions>((set, get) => ({
  ...initialState,
  
  // Управление персонажем
  setCharacter: (character) => set({ currentCharacter: character }),
  
  setVisibility: (isVisible) => set({ isVisible: isVisible }),
  
  setSpeaking: (speaking) => set({ isSpeaking: speaking }),
  
  setMuted: (muted) => set({ isMuted: muted }),
  
  setExpression: (expression) => set({ currentExpression: expression }),
  
  playAnimation: (animation) => set({ currentAnimation: animation }),
  
  stopAnimation: () => set({ currentAnimation: null }),
  
  // Взаимодействие с фразами
  showPhrase: (phrase) => {
    const phraseObj = typeof phrase === 'string' ? COMPANION_PHRASES.find(p => p.id === phrase) : phrase;
    if (phraseObj) {
      set((state) => ({ 
        currentPhrase: phraseObj,
        isSpeaking: true,
        currentExpression: phraseObj.expression || state.currentExpression,
        currentAnimation: phraseObj.animation || state.currentAnimation,
        isAnimating: true,
        phrasesHistory: [...state.phrasesHistory, phraseObj.id].slice(-10) // Ограничиваем историю последними 10 фразами
      }));
      
      // Автоматически сбрасываем анимацию к idle через длительность анимации + время чтения
      if (phraseObj.animation && phraseObj.animation !== 'idle') {
        const readingTime = phraseObj.text.length * 50; // Примерное время чтения в мс
        setTimeout(() => {
          const state = get();
          if (state.currentAnimation === phraseObj.animation) {
            get().stopAnimation();
          }
        }, readingTime);
      }
    }
  },
  
  showRandomPhraseByTag: (tag) => {
    const state = get();
    // Находим все фразы с нужным тегом
    const matchingPhrases = COMPANION_PHRASES.filter(p => 
      p.tags.includes(tag) && !state.phrasesHistory.includes(p.id)
    );
    
    if (matchingPhrases.length > 0) {
      // Выбираем случайную фразу
      const randomPhrase = matchingPhrases[Math.floor(Math.random() * matchingPhrases.length)];
      get().showPhrase(randomPhrase);
    } else {
      // Если все фразы были использованы, берем любую с нужным тегом
      const anyMatchingPhrases = COMPANION_PHRASES.filter(p => p.tags.includes(tag));
      if (anyMatchingPhrases.length > 0) {
        const randomPhrase = anyMatchingPhrases[Math.floor(Math.random() * anyMatchingPhrases.length)];
        get().showPhrase(randomPhrase);
      }
    }
  },
  
  showRandomPhraseByStep: (step) => {
    const phraseIds = STEP_TO_PHRASES_MAP[step] || [];
    if (phraseIds.length > 0) {
      const randomPhraseId = phraseIds[Math.floor(Math.random() * phraseIds.length)];
      get().showPhrase(randomPhraseId);
    }
  },
  
  clearPhrase: () => set({ 
    currentPhrase: null,
    isSpeaking: false,
    currentAnimation: null,
    isAnimating: false
  }),
  
  // Управление событиями
  handleEvent: (eventType, params = {}) => {
    const event = { ...COMPANION_EVENTS[eventType], params };
    
    set((state) => {
      // Сортируем существующую очередь по приоритету
      const sortedQueue = [...state.eventQueue, event].sort((a, b) => b.priority - a.priority);
      return { 
        eventQueue: sortedQueue,
        lastInteractionTime: Date.now() 
      };
    });
    
    // Немедленно обрабатываем очередь событий
    get().processEventQueue();
  },
  
  processEventQueue: () => {
    const state = get();
    if (state.eventQueue.length === 0 || state.isMuted) return;
    
    // Берем событие с наивысшим приоритетом
    const event = state.eventQueue[0];
    
    // Выбираем случайную фразу из подходящих для события
    if (event.phraseIds.length > 0) {
      const unusedPhraseIds = event.phraseIds.filter(id => !state.phrasesHistory.includes(id));
      const phraseId = unusedPhraseIds.length > 0
        ? unusedPhraseIds[Math.floor(Math.random() * unusedPhraseIds.length)]
        : event.phraseIds[Math.floor(Math.random() * event.phraseIds.length)];
      
      get().showPhrase(phraseId);
    }
    
    // Удаляем обработанное событие из очереди
    set((state) => ({
      eventQueue: state.eventQueue.slice(1)
    }));
  },
  
  // Обработка взаимодействия
  updateInteractionTime: () => set({ lastInteractionTime: Date.now() }),
  
  // Настройки
  setAutoHideTimeout: (timeout) => set({ autoHideTimeout: timeout }),
  
  setDisableIdleAnimation: (disable) => set({ disableIdleAnimation: disable }),
  
  setupIdleTimer: () => {
    const state = get();
    if (state.idleTimeoutId) clearTimeout(state.idleTimeoutId);
    set({
      idleTimeoutId: setTimeout(() => {
        get().clearIdleTimer();
      }, state.autoHideTimeout)
    });
  },
  
  clearIdleTimer: () => {
    const state = get();
    if (state.idleTimeoutId) {
      clearTimeout(state.idleTimeoutId);
      set({ idleTimeoutId: null });
    }
  }
})); 