import { motion } from 'framer-motion';

interface LoadingProps {
  title?: string;
  subtitle?: string;
}

export function Loading({ title = 'Загрузка...', subtitle }: LoadingProps) {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-[#f8f9fa] to-[#e9ecef] dark:from-gray-900 dark:to-gray-800">
      <div className="text-center p-8 rounded-xl bg-white/70 dark:bg-gray-800/70 backdrop-blur-sm shadow-xl max-w-md">
        <motion.div
          animate={{ 
            rotate: 360,
          }}
          transition={{ 
            repeat: Infinity, 
            duration: 1.5,
            ease: "linear" 
          }}
          className="w-16 h-16 border-4 border-primary/20 border-t-primary rounded-full mx-auto mb-6"
        />
        
        <motion.h2 
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="text-2xl font-bold text-gray-800 dark:text-gray-100 mb-2"
        >
          {title}
        </motion.h2>
        
        {subtitle && (
          <motion.p 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4 }}
            className="text-gray-600 dark:text-gray-300"
          >
            {subtitle}
          </motion.p>
        )}
      </div>
    </div>
  );
} 