import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { AnimatedPageWrapper, TransitionType, TransitionDirection } from '@/shared/ui/motion';
import { Loader } from '@/shared/ui/loader';
import { CompanionProvider } from '@/widgets/companion-character';
import { StoreProvider } from '@/app/providers/StoreProvider';
import { AudioProvider } from '@/shared/lib/audio/AudioContext';
import { AdminAuthProvider } from '@/features/admin-auth';

// Импорт базы данных и начальных данных
import { db } from '@/shared/lib/db/gift-database';
import initialGiftsData from '@/shared/data/gifts/initial-gifts.json';

// Импорт типа GiftCard
import type { GiftCard } from '@/shared/types/gift.types';
import { useEffect } from 'react';

// Безопасное приведение типов
const initialGifts = initialGiftsData as unknown as GiftCard[];

// Ленивая загрузка страниц
const HomePage = lazy(() => import('@/pages/home'));
const GiftSelectionPage = lazy(() => import('@/pages/gift-selection'));
const ResultsPage = lazy(() => import('@/pages/results'));
const NotFoundPage = lazy(() => import('@/pages/not-found'));
// Добавляем страницы праздников
const HolidaysPage = lazy(() => import('@/pages/holidays'));
const HolidayGiftSelectionPage = lazy(() => import('@/pages/holiday-gift-selection'));
// Добавляем страницу примера использования персонажа-компаньона
const CompanionExamplePage = lazy(() => import('@/pages/examples/CompanionExamplePage'));
// Добавляем экспериментальную страницу
const AdvancedResultsPage = lazy(() => import('@/pages/experimental/AdvancedResults'));
// Добавляем страницу админки для гендерных параметров
const GenderAdminPage = lazy(() => import('@/pages/admin'));
// Добавляем страницу статьи о подарках на 8 марта
const March8ArticlePage = lazy(() => import('@/pages/blog/march-8'));
// Добавляем страницу статьи о подарках маме на 8 марта
const MomGiftArticlePage = lazy(() => import('@/pages/blog/mom-gifts'));
// Добавляем страницу статьи о подарках подруге на 8 марта
const FriendGiftArticlePage = lazy(() => import('@/pages/blog/friend-gifts'));

// Конфигурация переходов для разных маршрутов
const routeTransitions = [
  {
    path: '/',
    type: 'fade' as TransitionType,
    direction: 'up' as TransitionDirection,
    duration: 0.5
  },
  {
    path: '/gift-selection',
    type: 'slide' as TransitionType,
    direction: 'left' as TransitionDirection,
    duration: 0.6
  },
  {
    path: '/results',
    type: 'zoom' as TransitionType,
    direction: 'center' as TransitionDirection,
    duration: 0.7,
    useGsap: true
  }
];

// Определяем SEO мета-данные для каждого маршрута
const routeSeoData = {
  '/': {
    title: 'Что подарить на день рождения и другие праздники | ЧтоПодарить.онлайн',
    description: 'Умный сервис подбора подарков поможет найти идеальный подарок с учетом интересов, возраста и бюджета. Персональные рекомендации за минуту!'
  },
  '/gift-selection': {
    title: 'Выбор идеального подарка - пройдите тест | ЧтоПодарить.онлайн',
    description: 'Пройдите интерактивный тест и получите персональные рекомендации подарков. Учитываем интересы, возраст, бюджет и повод.'
  },
  '/results': {
    title: 'Лучшие варианты подарков для вас | ЧтоПодарить.онлайн',
    description: 'Мы подобрали лучшие варианты подарков на основе ваших предпочтений. Найдите идеальный подарок прямо сейчас!'
  },
  '/holidays': {
    title: 'Подарки на праздники | ЧтоПодарить.онлайн',
    description: 'Подберите идеальный подарок к любому празднику. Новый год, 8 марта, День рождения и другие события - у нас есть идеи подарков для каждого случая!'
  },
  '/holiday': {
    title: 'Подбор подарка на праздник | ЧтоПодарить.онлайн',
    description: 'Персональные рекомендации подарков к празднику с учетом возраста, интересов и бюджета.'
  },
  '/chto-podarit-na-8-marta': {
    title: 'Что подарить на 8 марта - идеи подарков | ЧтоПодарить.онлайн',
    description: 'Подробная статья с идеями для подарков на 8 марта: идеи для жены, мамы, подруги и коллеги. Оригинальные подарки на любой бюджет.'
  },
  '/chto-podarit-mame-na-8-marta': {
    title: 'Что подарить маме на 8 марта - 30+ идей | ЧтоПодарить.онлайн',
    description: 'Более 30 идей, что подарить маме на 8 марта: от практичных до трогательных подарков. Выбирайте варианты на любой бюджет.'
  },
  '/chto-podarit-podruge-na-8-marta': {
    title: 'Что подарить подруге на 8 марта - подборка идей | ЧтоПодарить.онлайн',
    description: 'Подборка оригинальных подарков для подруги на 8 марта. Идеи для близкой подруги, коллеги или одноклассницы.'
  }
};

// Получаем SEO данные по текущему пути
const getSeoDataForRoute = (path: string) => {
  return routeSeoData[path as keyof typeof routeSeoData] || {
    title: 'ЧтоПодарить.онлайн - интерактивный сервис подбора подарков',
    description: 'Подбор идеальных подарков с учетом возраста, увлечений и бюджета за несколько простых шагов.'
  };
};

// Компонент-обертка для анимации страниц
const AnimatedRoutes = () => {
  const location = useLocation();
  const seoData = getSeoDataForRoute(location.pathname);
  
  return (
    <>
      <Helmet>
        <title>{seoData.title}</title>
        <meta name="description" content={seoData.description} />
        <link rel="canonical" href={`https://chtopodarit.online${location.pathname}`} />
        <meta property="og:title" content={seoData.title} />
        <meta property="og:description" content={seoData.description} />
        <meta property="og:url" content={`https://chtopodarit.online${location.pathname}`} />
      </Helmet>

      <Routes location={location} key={location.pathname}>
        <Route path="/" element={
          <AnimatedPageWrapper 
            routes={routeTransitions}
            defaultType="fade"
            defaultDirection="up"
            defaultDuration={0.5}
            adaptiveMode={true}
            className="min-h-screen"
          >
            <HomePage />
          </AnimatedPageWrapper>
        } />
        <Route path="/gift-selection" element={
          <AnimatedPageWrapper 
            routes={routeTransitions}
            defaultType="fade"
            defaultDirection="up"
            defaultDuration={0.5}
            adaptiveMode={true}
            className="min-h-screen"
          >
            <GiftSelectionPage />
          </AnimatedPageWrapper>
        } />
        <Route path="/results" element={
          <AnimatedPageWrapper 
            routes={routeTransitions}
            defaultType="fade"
            defaultDirection="up"
            defaultDuration={0.5}
            adaptiveMode={true}
            className="min-h-screen"
          >
            <ResultsPage />
          </AnimatedPageWrapper>
        } />
        <Route path="/examples/companion" element={
          <AnimatedPageWrapper 
            routes={routeTransitions}
            defaultType="fade"
            defaultDirection="up"
            defaultDuration={0.5}
            adaptiveMode={true}
            className="min-h-screen"
          >
            <CompanionExamplePage />
          </AnimatedPageWrapper>
        } />
        <Route path="/experimental/advanced-results" element={
          <AnimatedPageWrapper 
            routes={routeTransitions}
            defaultType="fade"
            defaultDirection="up"
            defaultDuration={0.5}
            adaptiveMode={true}
            className="min-h-screen"
          >
            <AdvancedResultsPage />
          </AnimatedPageWrapper>
        } />
        <Route path="/admin" element={
          <AnimatedPageWrapper 
            routes={routeTransitions}
            defaultType="fade"
            defaultDirection="up"
            defaultDuration={0.5}
            adaptiveMode={true}
            className="min-h-screen"
          >
            <GenderAdminPage />
          </AnimatedPageWrapper>
        } />
        <Route path="/chto-podarit-na-8-marta" element={
          <AnimatedPageWrapper 
            routes={routeTransitions}
            defaultType="fade"
            defaultDirection="up"
            defaultDuration={0.5}
            adaptiveMode={true}
            className="min-h-screen"
          >
            <March8ArticlePage />
          </AnimatedPageWrapper>
        } />
        <Route path="/chto-podarit-mame-na-8-marta" element={
          <AnimatedPageWrapper 
            routes={routeTransitions}
            defaultType="fade"
            defaultDirection="up"
            defaultDuration={0.5}
            adaptiveMode={true}
            className="min-h-screen"
          >
            <MomGiftArticlePage />
          </AnimatedPageWrapper>
        } />
        <Route path="/chto-podarit-podruge-na-8-marta" element={
          <AnimatedPageWrapper 
            routes={routeTransitions}
            defaultType="fade"
            defaultDirection="up"
            defaultDuration={0.5}
            adaptiveMode={true}
            className="min-h-screen"
          >
            <FriendGiftArticlePage />
          </AnimatedPageWrapper>
        } />
        <Route path="/holidays" element={
          <AnimatedPageWrapper 
            routes={routeTransitions}
            defaultType="fade"
            defaultDirection="up"
            defaultDuration={0.5}
            adaptiveMode={true}
            className="min-h-screen"
          >
            <HolidaysPage />
          </AnimatedPageWrapper>
        } />
        <Route path="/holiday/:holidayId" element={
          <AnimatedPageWrapper 
            routes={routeTransitions}
            defaultType="fade"
            defaultDirection="up"
            defaultDuration={0.5}
            adaptiveMode={true}
            className="min-h-screen"
          >
            <HolidayGiftSelectionPage />
          </AnimatedPageWrapper>
        } />
        <Route path="*" element={
          <AnimatedPageWrapper 
            routes={routeTransitions}
            defaultType="fade"
            defaultDirection="up"
            defaultDuration={0.5}
            adaptiveMode={true}
            className="min-h-screen"
          >
            <NotFoundPage />
          </AnimatedPageWrapper>
        } />
      </Routes>
    </>
  );
};

export function App() {
  // Хук для инициализации БД при монтировании компонента
  useEffect(() => {
    const initializeDatabase = async () => {
      try {
        const count = await db.gifts.count();
        if (count === 0) {
          console.log('База данных пуста, заполняем начальными данными...');
          await db.gifts.bulkAdd(initialGifts as GiftCard[]);
          console.log('База данных успешно заполнена.');
        } else {
          console.log('База данных уже содержит данные.');
        }
      } catch (error) {
        console.error('Ошибка при инициализации базы данных:', error);
      }
    };

    initializeDatabase();
  }, []); // Пустой массив зависимостей гарантирует выполнение только один раз

  return (
    <HelmetProvider>
      <StoreProvider>
        <AudioProvider>
          <Router>
            <CompanionProvider>
              <AdminAuthProvider>
                <Helmet>
                  {/* Глобальные мета-теги для всего сайта */}
                  <html lang="ru" />
                  <meta charSet="utf-8" />
                  <meta name="viewport" content="width=device-width, initial-scale=1" />
                  <meta name="theme-color" content="#f43f5e" />
                  <meta name="keywords" content="что подарить, идеи подарков, подарок на день рождения, что подарить маме, что подарить мужчине, подарок девушке, оригинальные подарки" />
                  <meta property="og:type" content="website" />
                  <meta property="og:site_name" content="ЧтоПодарить.онлайн" />
                  <meta property="og:image" content="https://chtopodarit.online/images/og-image.jpg" />
                  <meta name="twitter:card" content="summary_large_image" />
                  <meta name="twitter:image" content="https://chtopodarit.online/images/twitter-image.jpg" />
                  
                  {/* Структурированные данные для поисковых систем */}
                  <script type="application/ld+json">{`
                    {
                      "@context": "https://schema.org",
                      "@type": "WebSite",
                      "name": "ЧтоПодарить.онлайн",
                      "url": "https://chtopodarit.online/",
                      "description": "Интерактивный сервис по подбору идеальных подарков с учетом пола, возраста, увлечений и бюджета.",
                      "potentialAction": {
                        "@type": "SearchAction",
                        "target": "https://chtopodarit.online/search?q={search_term_string}",
                        "query-input": "required name=search_term_string"
                      }
                    }
                  `}</script>
                </Helmet>
                
                <Suspense fallback={<Loader fullScreen size="lg" message="Загружаем подарки..." />}>
                  <AnimatedRoutes />
                </Suspense>
              </AdminAuthProvider>
            </CompanionProvider>
          </Router>
        </AudioProvider>
      </StoreProvider>
    </HelmetProvider>
  );
}

export default App;
