import { useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useCompanionStore } from '@/features/gamification/model/companion-store';
import { useGiftSelectionStore } from '@/features/gift-selection/model/store';
import { STEP_TO_PHRASES_MAP, IDLE_TIMEOUT } from '@/widgets/companion-character/model';

/**
 * Хук для управления персонажем-компаньоном в процессе выбора подарка
 * @param options Дополнительные опции для настройки поведения персонажа
 */
export function useCompanionManager(
  options: {
    /** Отключить автоматические реакции на изменение шага */
    disableStepReactions?: boolean;
    /** Отключить реакции на бездействие */
    disableIdleReactions?: boolean;
    /** Отключить реакции на выбор параметров */
    disableSelectionReactions?: boolean;
    /** Интервал проверки бездействия в мс */
    idleCheckInterval?: number;
  } = {}
) {
  const {
    disableStepReactions = false,
    disableIdleReactions = false,
    disableSelectionReactions = false,
    idleCheckInterval = 5000 // По умолчанию проверяем каждые 5 секунд
  } = options;

  const location = useLocation();
  
  // Zustand stores
  const {
    handleEvent,
    lastInteractionTime,
    isVisible,
    isSpeaking,
    showRandomPhraseByTag,
    showRandomPhraseByStep,
    showPhrase,
    setVisible,
    updateLastInteractionTime,
    autoHideTimeout
  } = useCompanionStore();
  
  const { 
    currentStep,
    selectedCharacter,
    age,
    selectedBudget,
    selectedInterests,
    practicality,
    uniqueness,
    sentimentality
  } = useGiftSelectionStore();
  
  // Определение текущего шага из URL
  const getCurrentStep = useCallback(() => {
    const path = location.pathname;
    
    if (path.includes('/gift-selection/character')) return 'character';
    if (path.includes('/gift-selection/age')) return 'age';
    if (path.includes('/gift-selection/budget')) return 'budget';
    if (path.includes('/gift-selection/interests')) return 'interests';
    if (path.includes('/gift-selection/preferences')) return 'preferences';
    if (path.includes('/gift-selection/results')) return 'results';
    
    return null;
  }, [location]);
  
  // Эффект для реакции на изменение шага
  useEffect(() => {
    if (disableStepReactions) return;
    
    const step = getCurrentStep();
    if (step) {
      handleEvent('step_change', { step });
      showRandomPhraseByStep(step);
    }
  }, [getCurrentStep, disableStepReactions, handleEvent, showRandomPhraseByStep]);
  
  // Эффект для реакции на выбор параметров
  useEffect(() => {
    if (disableSelectionReactions) return;
    
    // Реакция на выбор персонажа
    if (selectedCharacter) {
      handleEvent('character_selected', { character: selectedCharacter });
    }
    
    // Реакция на выбор возраста
    if (age) {
      handleEvent('age_selected', { age: age });
    }
    
    // Реакция на выбор бюджета
    if (selectedBudget) {
      handleEvent('budget_selected', { budget: selectedBudget });
    }
    
  }, [selectedCharacter, age, selectedBudget, disableSelectionReactions, handleEvent]);
  
  // Эффект для реакции на выбор интересов
  useEffect(() => {
    if (disableSelectionReactions || !selectedInterests) return;
    
    // Если выбрано много интересов, показываем специальную фразу
    if (selectedInterests.length > 3) {
      showRandomPhraseByTag('interests_many_selected');
    } else if (selectedInterests.length === 0) {
      // Если интересы не выбраны, предлагаем выбрать
      showRandomPhraseByTag('interests_few_selected');
    }
  }, [selectedInterests, disableSelectionReactions, showRandomPhraseByTag]);
  
  // Эффект для реакции на изменение слайдеров предпочтений
  useEffect(() => {
    if (disableSelectionReactions) return;
    
    // Реагируем на изменения слайдеров (с дебаунсом)
    const timer = setTimeout(() => {
      handleEvent('slider_changed', { 
        practicality,
        uniqueness,
        sentimentality
      });
    }, 500);
    
    return () => clearTimeout(timer);
  }, [practicality, uniqueness, sentimentality, disableSelectionReactions, handleEvent]);
  
  // Эффект для реакции на бездействие пользователя
  useEffect(() => {
    if (disableIdleReactions) return;
    
    const idleTimer = setInterval(() => {
      const now = Date.now();
      const idleTime = now - lastInteractionTime;
      
      // Если пользователь не взаимодействовал достаточно долго и персонаж не говорит
      if (idleTime >= autoHideTimeout && isVisible && !isSpeaking) {
        handleEvent('idle_timeout', { idleTime });
      }
    }, idleCheckInterval);
    
    return () => clearInterval(idleTimer);
  }, [
    disableIdleReactions, 
    lastInteractionTime, 
    handleEvent, 
    idleCheckInterval, 
    isVisible, 
    isSpeaking,
    autoHideTimeout
  ]);
  
  // При восстановлении сессии
  const handleSessionRestore = useCallback(() => {
    handleEvent('session_restored');
  }, [handleEvent]);
  
  // При ошибке
  const handleError = useCallback((errorMessage: string) => {
    handleEvent('error_occurred', { message: errorMessage });
  }, [handleEvent]);
  
  return {
    // Методы для ручного управления персонажем
    handleStepChange: (step: string) => {
      handleEvent('step_change', { step });
      showRandomPhraseByStep(step);
    },
    handleSessionRestore,
    handleError,
    showRandomPhraseByTag,
    showPhrase,
    updateInteraction: updateLastInteractionTime,
    setVisible
  };
}