import { useAdaptiveAnimations } from '@/shared/lib/device';
import { cn } from '@/shared/lib/utils';

interface LoaderProps {
  size?: 'sm' | 'md' | 'lg';
  variant?: 'primary' | 'secondary' | 'light' | 'dark';
  message?: string;
  fullScreen?: boolean;
  className?: string;
  messageClassName?: string;
}

/**
 * Компонент для отображения состояния загрузки с адаптивной анимацией
 */
export function Loader({
  size = 'md',
  variant = 'primary',
  message,
  fullScreen = false,
  className = '',
  messageClassName = '',
}: LoaderProps) {
  // Получаем адаптивные настройки анимации
  const adaptiveSettings = useAdaptiveAnimations({
    complexity: 'normal',
  });
  
  // Размеры в зависимости от параметра size
  const sizeClasses = {
    sm: 'w-6 h-6 border-2',
    md: 'w-10 h-10 border-3',
    lg: 'w-16 h-16 border-4',
  };
  
  // Цвета в зависимости от параметра variant
  const variantClasses = {
    primary: 'border-t-primary-500 border-r-primary-500 border-b-transparent border-l-transparent',
    secondary: 'border-t-secondary-500 border-r-secondary-500 border-b-transparent border-l-transparent',
    light: 'border-t-white border-r-white border-b-transparent border-l-transparent',
    dark: 'border-t-gray-800 border-r-gray-800 border-b-transparent border-l-transparent',
  };
  
  // Определяем класс для скорости анимации на основе адаптивных настроек
  const spinSpeed = adaptiveSettings.prefersReducedMotion
    ? 'animate-spin-slow' // Медленное вращение для пользователей с предпочтением уменьшенного движения
    : 'animate-spin';
  
  // Базовый класс для кружка загрузки
  const spinnerBaseClass = 'rounded-full';
  
  // Создаем классы для внешнего кружка с пульсацией
  const outerCircleBaseClass = 'absolute rounded-full opacity-20';
  const outerCircleSize = {
    sm: 'w-10 h-10 border-2',
    md: 'w-16 h-16 border-3',
    lg: 'w-24 h-24 border-4',
  };
  const outerCircleVariant = {
    primary: 'border-primary-300 dark:border-primary-800',
    secondary: 'border-secondary-300 dark:border-secondary-800',
    light: 'border-white/30',
    dark: 'border-gray-800/30',
  };
  
  // Определяем наличие пульсации внешнего круга на основе адаптивных настроек
  const pulseClass = (!adaptiveSettings.prefersReducedMotion && adaptiveSettings.complexity !== 'simple')
    ? 'animate-pulse-slow'
    : '';
  
  // Определяем базовый контейнер, зависящий от параметра fullScreen
  const containerClass = fullScreen 
    ? 'fixed inset-0 flex items-center justify-center bg-gray-100/80 dark:bg-gray-900/80 backdrop-blur-sm z-50'
    : 'flex flex-col items-center justify-center';
  
  return (
    <div className={cn(containerClass, className)}>
      <div className="relative flex items-center justify-center">
        {/* Внешний пульсирующий круг (только если включена сложная анимация) */}
        {adaptiveSettings.complexity !== 'simple' && (
          <div className={cn(
            outerCircleBaseClass,
            outerCircleSize[size],
            outerCircleVariant[variant],
            pulseClass
          )} />
        )}
        
        {/* Вращающийся круг загрузки */}
        <div className={cn(
          spinnerBaseClass,
          sizeClasses[size],
          variantClasses[variant],
          spinSpeed
        )} />
      </div>
      
      {/* Сообщение под индикатором загрузки */}
      {message && (
        <p className={cn(
          'mt-4 font-medium text-gray-600 dark:text-gray-300',
          messageClassName
        )}>
          {message}
        </p>
      )}
    </div>
  );
}