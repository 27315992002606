import { useState, useEffect } from 'react';
import { db, dbStatus, localStorageDb } from '@/shared/lib/db/gift-database';
import {
  type GiftCard,
  DEFAULT_GIFT_RATING,
  GENDER_AFFINITY,
} from '@/shared/types/gift.types';
import { useGiftSelectionStore } from '@/features/gift-selection/model/store';
import { initialGifts } from '@/shared/data/initial-gifts';

interface UseInitializeDbResult {
  isLoading: boolean;
  isSuccess: boolean;
  error: Error | null;
  dbUnavailable: boolean;
}

/**
 * Хук для инициализации базы данных при запуске приложения.
 * Проверяет наличие записей в БД и добавляет начальные данные, если БД пуста.
 */
export function useInitializeDb(): UseInitializeDbResult {
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [dbUnavailable, setDbUnavailable] = useState(false);
  
  const { setDbInitialized, setDbError } = useGiftSelectionStore();

  useEffect(() => {
    async function initializeDb() {
      try {
        // Проверяем доступность IndexedDB
        const isDbAvailable = await db.safeOpen();
        
        if (!isDbAvailable) {
          setDbUnavailable(true);
          setDbError("IndexedDB недоступен. Используется локальное хранилище");
          console.warn("IndexedDB недоступен, используем localStorage в качестве fallback");
          
          // Инициализируем fallback локальное хранилище
          const storedGifts = await localStorageDb.get<GiftCard[]>('gifts');
          if (!storedGifts || storedGifts.length === 0) {
            await localStorageDb.set('gifts', initialGifts);
          }
          
          // Проверяем наличие незавершенных сессий
          const hasUnfinishedSessions = await checkUnfinishedSessionsInLocalStorage();
          
          setIsSuccess(true);
          setIsLoading(false);
          setDbInitialized(true);
          return;
        }
        
        // Продолжаем с IndexedDB если он доступен
        // Проверяем наличие начальных данных в таблице gifts
        const giftCount = await db.gifts.count();

        if (giftCount === 0) {
          // Если таблица пуста, добавляем начальные данные
          await db.gifts.bulkAdd(initialGifts);
          console.log(`Добавлено ${initialGifts.length} начальных подарков`);
        }

        // Проверяем наличие незавершенных сессий
        const hasUnfinishedSessions = await checkUnfinishedSessions();

        setIsSuccess(true);
        setIsLoading(false);
        setDbInitialized(true);
      } catch (e) {
        console.error('Ошибка при инициализации базы данных:', e);
        
        const errorMessage = e instanceof Error 
          ? e.message 
          : 'Произошла ошибка при инициализации базы данных';
        
        const error = new Error(errorMessage);
        setError(error);
        setDbError(errorMessage);
        setIsLoading(false);
        setIsSuccess(false);
        
        // Проверяем, можем ли мы использовать localStorage как fallback
        if (!dbStatus.useLocalStorageFallback) {
          try {
            dbStatus.useLocalStorageFallback = true;
            setDbUnavailable(true);
            
            // Инициализируем localStorage
            const storedGifts = await localStorageDb.get<GiftCard[]>('gifts');
            if (!storedGifts || storedGifts.length === 0) {
              await localStorageDb.set('gifts', initialGifts);
            }
            
            setDbInitialized(true);
            setIsSuccess(true);
            console.log('Успешно переключились на localStorage в качестве fallback');
          } catch (localStorageError) {
            console.error('Ошибка при использовании localStorage fallback:', localStorageError);
          }
        }
      }
    }

    initializeDb();
  }, [setDbInitialized, setDbError]);

  return {
    isLoading,
    isSuccess,
    error,
    dbUnavailable,
  };
}

/**
 * Проверяет наличие незавершенных сессий в базе данных.
 * @returns true, если есть хотя бы одна незавершенная сессия
 */
async function checkUnfinishedSessions(): Promise<boolean> {
  try {
    // Проверяем наличие активных сессий
    const activeSessions = await db.sessions
      .where('isActive')
      .equals(1)
      .toArray();

    return activeSessions.length > 0;
  } catch (e) {
    console.error('Ошибка при проверке незавершенных сессий:', e);
    return false;
  }
}

/**
 * Проверяет наличие незавершенных сессий в localStorage.
 * @returns true, если есть хотя бы одна незавершенная сессия
 */
async function checkUnfinishedSessionsInLocalStorage(): Promise<boolean> {
  try {
    const sessions = await localStorageDb.get<any[]>('sessions');
    if (!sessions) return false;
    
    // Проверяем наличие активных сессий
    const activeSessions = sessions.filter(session => session.isActive === 1);
    return activeSessions.length > 0;
  } catch (e) {
    console.error('Ошибка при проверке незавершенных сессий в localStorage:', e);
    return false;
  }
}

interface CheckDatabaseVersionResult {
  currentVersion: number;
  latestVersion: number;
  needsMigration: boolean;
}

/**
 * Проверяет версию базы данных и выполняет миграцию, если необходимо.
 */
export async function checkAndMigrateDatabase(): Promise<CheckDatabaseVersionResult> {
  const currentVersion = db.verno;
  const latestVersion = 2; // Актуальная версия схемы БД

  const needsMigration = currentVersion < latestVersion;

  if (needsMigration) {
    // Миграции автоматически выполняются Dexie при открытии БД
    // с новой версией в конструкторе, поэтому дополнительных действий не требуется
    console.log(`Требуется миграция БД с версии ${currentVersion} до ${latestVersion}`);
  }

  return {
    currentVersion,
    latestVersion,
    needsMigration,
  };
}

// Для возможности импорта без использования хука, если потребуется
// export async function initializeDatabase(): Promise<void> {
//   try {
//     const count = await db.gifts.count();
//     if (count === 0) {
//       console.log('Initializing database with initial gift data...');
//       const validatedGifts = initialGifts as GiftCard[];
//       await db.gifts.bulkAdd(validatedGifts);
//       console.log('Database initialized successfully.');
//     }
//   } catch (error) {
//     console.error('Failed to initialize database:', error);
//     throw error; // Пробрасываем ошибку для дальнейшей обработки
//   }
// } 