import { createContext, useContext, FC, ReactNode } from 'react';
import { useCompanionManager } from './useCompanionManager';
import { useCompanionStore } from '@/features/gamification/model/companion-store';
import { CompanionCharacter } from '@/widgets/companion-character/model';

/**
 * Интерфейс контекста компаньона
 */
interface CompanionContextType {
  // Методы из useCompanionManager
  handleStepChange: (step: string) => void;
  handleSessionRestore: () => void;
  handleError: (errorMessage: string) => void;
  showRandomPhraseByTag: (tag: string) => void;
  showPhrase: (phraseId: string) => void;
  updateInteraction: () => void;
  setVisible: (visible: boolean) => void;
  
  // Состояние из useCompanionStore
  currentCharacter: CompanionCharacter;
  isVisible: boolean;
  isSpeaking: boolean;
  isMuted: boolean;
}

/**
 * Создаем контекст компаньона с начальными значениями
 */
const CompanionContext = createContext<CompanionContextType | null>(null);

/**
 * Интерфейс для провайдера контекста компаньона
 */
interface CompanionProviderProps {
  children: ReactNode;
  managerOptions?: Parameters<typeof useCompanionManager>[0];
}

/**
 * Провайдер контекста компаньона
 */
export const CompanionProvider: FC<CompanionProviderProps> = ({ 
  children,
  managerOptions = {}
}) => {
  // Получаем методы из хука управления компаньоном
  const { 
    handleStepChange,
    handleSessionRestore,
    handleError,
    showRandomPhraseByTag,
    showPhrase,
    updateInteraction,
    setVisible
  } = useCompanionManager(managerOptions);
  
  // Получаем состояние из хранилища компаньона
  const {
    currentCharacter,
    isVisible,
    isSpeaking,
    isMuted
  } = useCompanionStore();
  
  // Объединяем методы и состояние в один объект для контекста
  const contextValue: CompanionContextType = {
    // Методы
    handleStepChange,
    handleSessionRestore,
    handleError,
    showRandomPhraseByTag,
    showPhrase,
    updateInteraction,
    setVisible,
    
    // Состояние
    currentCharacter,
    isVisible,
    isSpeaking,
    isMuted
  };
  
  return (
    <CompanionContext.Provider value={contextValue}>
      {children}
    </CompanionContext.Provider>
  );
};

/**
 * Хук для использования контекста компаньона
 */
export function useCompanion() {
  const context = useContext(CompanionContext);
  
  if (!context) {
    throw new Error('useCompanion должен использоваться внутри CompanionProvider');
  }
  
  return context;
} 