import { motion } from 'framer-motion';

interface ErrorMessageProps {
  title: string;
  message: string;
  error?: Error | null;
}

export function ErrorMessage({ title, message, error }: ErrorMessageProps) {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-[#fde2e2] to-[#fcf1f1] dark:from-gray-900 dark:to-gray-800">
      <motion.div 
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        className="text-center p-8 rounded-xl bg-white/70 dark:bg-gray-800/70 backdrop-blur-sm shadow-xl max-w-md border-l-4 border-red-500"
      >
        <div className="mx-auto w-16 h-16 mb-6 flex items-center justify-center rounded-full bg-red-100 dark:bg-red-900/30">
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 24 24" 
            fill="currentColor" 
            className="w-8 h-8 text-red-500 dark:text-red-400"
          >
            <path 
              fillRule="evenodd" 
              d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z" 
              clipRule="evenodd" 
            />
          </svg>
        </div>
        
        <motion.h2 
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="text-2xl font-bold text-red-600 dark:text-red-400 mb-2"
        >
          {title}
        </motion.h2>
        
        <motion.p 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4 }}
          className="text-gray-700 dark:text-gray-300 mb-6"
        >
          {message}
        </motion.p>

        {error && (
          <div className="mt-4 p-3 bg-gray-100 dark:bg-gray-700/50 rounded text-left text-sm text-gray-600 dark:text-gray-400 font-mono overflow-auto max-h-32">
            {error.message}
          </div>
        )}
        
        <motion.button
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6 }}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => window.location.reload()}
          className="mt-6 px-6 py-2 bg-primary hover:bg-primary-600 text-white rounded-md font-medium focus:outline-none focus:ring-2 focus:ring-primary-400 focus:ring-offset-2 transition-colors"
        >
          Обновить страницу
        </motion.button>
      </motion.div>
    </div>
  );
} 