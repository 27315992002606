import { create } from 'zustand';
import type { GiftTag } from '@/shared/types/gift.types';
import { HOLIDAY_PRESETS } from './holiday-presets';

// Определяем возможные шаги процесса выбора
export type SelectionStep = 'character' | 'age' | 'budget' | 'interests' | 'preferences' | 'results';

// Базовый интерфейс состояния
interface GiftSelectionStateBase {
  /** Текущий шаг выбора подарка */
  currentStep: SelectionStep;
  /** Выбранный персонаж (начальный выбор мастера) */
  selectedCharacter: string | null;
  /** Выбранный возраст */
  age: number | null;
  /** Выбранные отношения */
  relationship: string | null;
  /** Выбранная категория возраста */
  selectedAgeCategory: string | null;
  /** Пока строка для категории (budget, mid-range, premium) */
  selectedBudget: string | null;
  /** Выбранные интересы */
  selectedInterests: GiftTag[];
  /** Инициализирована ли база данных */
  isDbInitialized: boolean;
  /** Ошибка инициализации базы данных */
  dbError: string | null;
  /** Новые поля для экрана предпочтений */
  practicality: number;
  uniqueness: number;
  sentimentality: number;
  /** Флаг режима "Мне повезет!" */
  isRandomMode: boolean;
  /** Гендерная направленность подарка */
  selectedGender: 'male' | 'female' | 'any' | null;
  /** Выбранный праздник */
  selectedHoliday: string | null;
}

// Интерфейс с методами
interface GiftSelectionActions {
  setStep: (step: SelectionStep) => void;
  setCharacter: (character: string | null) => void;
  setAge: (age: number | null) => void;
  setRelationship: (relationship: string | null) => void;
  setAgeCategory: (ageCategory: string | null) => void;
  setBudget: (budget: string | null) => void;
  toggleInterest: (interest: GiftTag) => void;
  setPracticality: (value: number) => void;
  setUniqueness: (value: number) => void;
  setSentimentality: (value: number) => void;
  setDbInitialized: (value: boolean) => void;
  setDbError: (error: string | null) => void;
  setRandomMode: (value: boolean) => void;
  setGender: (gender: 'male' | 'female' | 'any' | null) => void;
  setHoliday: (holiday: string | null) => void;
  applyHolidayPreset: (holidayId: string) => void;
  resetSelections: () => void;
}

// Полный интерфейс состояния стора
export type GiftSelectionState = GiftSelectionStateBase & GiftSelectionActions;

// Начальное состояние
const initialState: GiftSelectionStateBase = {
  currentStep: 'character',
  selectedCharacter: null,
  age: null, // Начальное значение возраста
  relationship: null, // Начальное значение отношений
  selectedAgeCategory: null, // Инициализируем категорию
  selectedBudget: null,
  selectedInterests: [],
  // Начальные значения для предпочтений (средние)
  practicality: 50,
  uniqueness: 50,
  sentimentality: 50,
  isDbInitialized: false, // Начальное значение флага
  isRandomMode: false, // Исходно режим "Мне повезет!" не активирован
  selectedGender: null, // Начальное значение пола
  dbError: null,
  selectedHoliday: null, // Начальное значение выбранного праздника
};

// Создаем хранилище с использованием Zustand
export const useGiftSelectionStore = create<GiftSelectionState>()((set) => ({
  ...initialState,

  // Устанавливаем текущий шаг
  setStep: (step) => set({ currentStep: step }),
  
  // Устанавливаем выбранного персонажа
  setCharacter: (character) => set({ selectedCharacter: character }),
  
  // Устанавливаем возраст
  setAge: (age) => set({ age }),
  
  // Устанавливаем отношения
  setRelationship: (relationship) => set({ relationship }),
  
  // Устанавливаем возрастную категорию
  setAgeCategory: (ageCategory) => set({ selectedAgeCategory: ageCategory }),
  
  // Устанавливаем бюджет
  setBudget: (budget) => set({ selectedBudget: budget }),
  
  // Переключаем интерес (добавляем/удаляем)
  toggleInterest: (interest) => set((state) => {
    const interests = [...state.selectedInterests];
    const index = interests.indexOf(interest);
    
    if (index === -1) {
      // Если интереса нет в списке, добавляем его
      interests.push(interest);
    } else {
      // Если интерес уже есть в списке, удаляем его
      interests.splice(index, 1);
    }
    
    return { selectedInterests: interests };
  }),
  
  // Устанавливаем значение практичности
  setPracticality: (value) => set({ practicality: value }),
  
  // Устанавливаем значение уникальности
  setUniqueness: (value) => set({ uniqueness: value }),
  
  // Устанавливаем значение сентиментальности
  setSentimentality: (value) => set({ sentimentality: value }),
  
  // Устанавливаем флаг инициализации базы данных
  setDbInitialized: (value) => set({ isDbInitialized: value }),
  
  // Устанавливаем ошибку инициализации базы данных
  setDbError: (error) => set({ dbError: error }),
  
  // Устанавливаем режим "Мне повезет!"
  setRandomMode: (value) => set({ isRandomMode: value }),
  
  // Устанавливаем пол
  setGender: (gender) => set({ selectedGender: gender }),
  
  // Устанавливаем выбранный праздник
  setHoliday: (holiday) => set({ selectedHoliday: holiday }),
  
  // Применяем праздничный пресет
  applyHolidayPreset: (holidayId) => {
    const preset = HOLIDAY_PRESETS[holidayId];
    if (!preset) return;
    
    set(state => ({
      selectedHoliday: holidayId,
      // Сначала сбрасываем текущие интересы
      selectedInterests: [],
      // Применяем параметры из пресета
      ...(preset.gender && { selectedGender: 
        preset.gender === 'мужской' ? 'male' : 
        preset.gender === 'женский' ? 'female' : 'any' 
      }),
      ...(preset.relationship && { relationship: preset.relationship }),
      // Устанавливаем средний возраст из возрастного диапазона
      ...(preset.age && { age: Math.floor((preset.age[0] + preset.age[1]) / 2) }),
      // Определяем возрастную категорию на основе возрастного диапазона
      ...(preset.age && { 
        selectedAgeCategory: 
          preset.age[1] <= 18 ? 'child' : 
          preset.age[0] >= 35 ? 'adult' : 
          'young-adult' 
      }),
    }));
    
    // Добавляем интересы по одному, если они есть
    if (preset.interests && preset.interests.length > 0) {
      // Преобразуем интересы из русских названий в теги GiftTag
      const interestTagMap: Record<string, GiftTag> = {
        'красота': 'beauty',
        'мода': 'beauty',
        'уют': 'home',
        'хобби': 'hobby',
        'развлечения': 'hobby',
        'дом': 'home',
        'технологии': 'electronics',
        'спорт': 'sports',
        'отдых': 'hobby',
        'романтика': 'unisex',
        'книги': 'books',
        'игрушки': 'toys',
        'еда': 'home',
      };
      
      preset.interests.forEach(interest => {
        const tag = interestTagMap[interest];
        if (tag) {
          useGiftSelectionStore.getState().toggleInterest(tag);
        }
      });
    }
    
    // Устанавливаем бюджет на основе priceRange
    if (preset.priceRange) {
      const [min, max] = preset.priceRange;
      let budgetCategory;
      
      if (max <= 3000) {
        budgetCategory = 'эконом';
      } else if (max <= 7000) {
        budgetCategory = 'средний';
      } else if (max <= 15000) {
        budgetCategory = 'премиум';
      } else {
        budgetCategory = 'люкс';
      }
      
      set({ selectedBudget: budgetCategory });
    }
  },
  
  // Сбрасываем все выборы к начальным значениям
  resetSelections: () => set({
    ...initialState,
    isDbInitialized: useGiftSelectionStore.getState().isDbInitialized,
    dbError: useGiftSelectionStore.getState().dbError,
  }),
})); 