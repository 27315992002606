import { useState, useEffect, useCallback } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { TransitionType, TransitionDirection } from '@/shared/ui/motion';
import { useAdaptiveAnimations } from '@/shared/lib/device';

export interface RouteTransitionConfig {
  path: string; 
  type?: TransitionType;
  direction?: TransitionDirection;
  duration?: number;
  useGsap?: boolean;
}

export interface UsePageTransitionOptions {
  defaultType?: TransitionType;
  defaultDirection?: TransitionDirection;
  defaultDuration?: number;
  defaultUseGsap?: boolean;
  routes?: RouteTransitionConfig[];
  adaptiveMode?: boolean;
}

/**
 * Хук для управления анимированными переходами между страницами
 * 
 * Позволяет конфигурировать анимации для конкретных маршрутов,
 * или использовать настройки по умолчанию
 * 
 * В адаптивном режиме учитывает характеристики устройства
 */
export function usePageTransition({
  defaultType = 'fade',
  defaultDirection = 'up',
  defaultDuration = 0.5,
  defaultUseGsap = false,
  routes = [],
  adaptiveMode = true
}: UsePageTransitionOptions = {}) {
  const location = useLocation();
  const [prevPath, setPrevPath] = useState<string>(location.pathname);
  const [transitionType, setTransitionType] = useState<TransitionType>(defaultType);
  const [transitionDirection, setTransitionDirection] = useState<TransitionDirection>(defaultDirection);
  const [transitionDuration, setTransitionDuration] = useState<number>(defaultDuration);
  const [useGsap, setUseGsap] = useState<boolean>(defaultUseGsap);
  
  // Получаем адаптивные настройки анимаций, основанные на возможностях устройства
  const adaptiveSettings = useAdaptiveAnimations({
    duration: defaultDuration,
    complexity: 'normal',
  });
  
  // Определяем направление перехода на основе истории навигации
  const determineDirection = useCallback((newPath: string): TransitionDirection => {
    // Здесь можно реализовать логику для определения направления
    // на основе предыдущего и текущего пути
    // Например, если у нас есть иерархия маршрутов:
    
    const pathDepth = (path: string): number => {
      return path.split('/').filter(Boolean).length;
    };
    
    const prevDepth = pathDepth(prevPath);
    const newDepth = pathDepth(newPath);
    
    if (prevDepth < newDepth) {
      return 'left'; // Переход глубже - слева направо
    } else if (prevDepth > newDepth) {
      return 'right'; // Переход наверх - справа налево
    }
    
    // Можно также определять направление на основе порядковых номеров
    // страниц в линейном пользовательском потоке навигации
    
    return defaultDirection;
  }, [prevPath, defaultDirection]);
  
  // Находим подходящую конфигурацию перехода для текущего маршрута
  const findRouteTransition = useCallback((path: string): RouteTransitionConfig | undefined => {
    if (!routes || routes.length === 0) return undefined;
    
    // Ищем маршрут, который соответствует текущему пути
    return routes.find(route => matchPath(route.path, path));
  }, [routes]);
  
  // Обновляем настройки перехода при изменении маршрута
  useEffect(() => {
    // Если путь не изменился, ничего не делаем
    if (location.pathname === prevPath) return;
    
    // Находим конфигурацию для нового маршрута
    const routeTransition = findRouteTransition(location.pathname);
    
    // Определяем направление перехода
    const direction = routeTransition?.direction || determineDirection(location.pathname);
    
    // Адаптируем настройки в зависимости от возможностей устройства, если включен адаптивный режим
    if (adaptiveMode) {
      const adaptiveType: TransitionType = (() => {
        // В первую очередь используем тип из конфигурации маршрута, если есть
        if (routeTransition?.type) return routeTransition.type;
        
        // Если устройство слабое или пользователь предпочитает уменьшенное движение, используем fade
        if (adaptiveSettings.complexity === 'simple') {
          return 'fade';
        }
        
        // Для средней производительности - простые анимации
        if (adaptiveSettings.complexity === 'normal') {
          return defaultType === 'blur' || defaultType === 'wipe' ? 'slide' : defaultType;
        }
        
        // Для высокой производительности - любые анимации
        return defaultType;
      })();
      
      // Определяем длительность анимации
      const adaptiveDuration = routeTransition?.duration || adaptiveSettings.duration;
      
      // Определяем, использовать ли GSAP (только для устройств с высокой производительностью)
      const adaptiveUseGsap = (routeTransition?.useGsap !== undefined)
        ? routeTransition.useGsap
        : (defaultUseGsap && adaptiveSettings.complexity === 'complex');
      
      setTransitionType(adaptiveType);
      setTransitionDuration(adaptiveDuration);
      setTransitionDirection(direction);
      setUseGsap(adaptiveUseGsap);
    } else {
      // Если адаптивный режим выключен, просто используем настройки маршрута или дефолтные
      setTransitionType(routeTransition?.type || defaultType);
      setTransitionDuration(routeTransition?.duration || defaultDuration);
      setTransitionDirection(direction);
      setUseGsap(routeTransition?.useGsap !== undefined ? routeTransition.useGsap : defaultUseGsap);
    }
    
    // Обновляем предыдущий путь
    setPrevPath(location.pathname);
  }, [
    location.pathname, 
    prevPath, 
    findRouteTransition, 
    determineDirection, 
    defaultType, 
    defaultDirection, 
    defaultDuration, 
    defaultUseGsap, 
    adaptiveMode, 
    adaptiveSettings
  ]);
  
  return {
    type: transitionType,
    direction: transitionDirection,
    duration: transitionDuration,
    useGsap,
    locationKey: location.key, // Уникальный ключ для перехода
    path: location.pathname, // Текущий путь
  };
} 