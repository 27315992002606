import { ReactNode } from 'react';
import { usePageTransition } from '@/shared/lib/hooks';
import { AdvancedTransition, TransitionType, TransitionDirection } from './AdvancedTransition';

interface RouteTransitionConfig {
  path: string;
  type?: TransitionType;
  direction?: TransitionDirection;
  duration?: number;
  useGsap?: boolean;
}

interface AnimatedPageWrapperProps {
  children: ReactNode;
  defaultType?: TransitionType;
  defaultDirection?: TransitionDirection;
  defaultDuration?: number;
  defaultUseGsap?: boolean;
  routes?: RouteTransitionConfig[];
  adaptiveMode?: boolean;
  className?: string;
  wrapperClassName?: string;
}

/**
 * Компонент-обёртка для анимированных переходов между страницами
 * 
 * Использует хук usePageTransition для определения настроек анимации
 * и компонент AdvancedTransition для выполнения анимации
 * 
 * Позволяет настроить разные типы анимаций для разных маршрутов
 */
export function AnimatedPageWrapper({
  children,
  defaultType = 'fade',
  defaultDirection = 'up',
  defaultDuration = 0.5,
  defaultUseGsap = false,
  routes = [],
  adaptiveMode = true,
  className = '',
  wrapperClassName = '',
}: AnimatedPageWrapperProps) {
  // Получаем настройки перехода для текущей страницы
  const {
    type,
    direction,
    duration,
    useGsap,
    locationKey,
  } = usePageTransition({
    defaultType,
    defaultDirection,
    defaultDuration,
    defaultUseGsap,
    routes,
    adaptiveMode,
  });
  
  return (
    <div className={`w-full h-full ${wrapperClassName}`}>
      <AdvancedTransition
        key={locationKey} // Используем ключ локации для сброса анимации при изменении пути
        type={type}
        direction={direction}
        duration={duration}
        useGsap={useGsap}
        className={className}
        exitBeforeEnter={true}
      >
        {children}
      </AdvancedTransition>
    </div>
  );
} 