import Dexie, { type Table } from 'dexie';
import type { GiftCard } from '@/shared/types/gift.types';
import type { UserSession } from '@/features/session-saving/model/types';

// Проверяем доступность IndexedDB
function isIndexedDBAvailable(): boolean {
  try {
    // Проверка наличия window и IndexedDB
    if (typeof window === 'undefined' || !window.indexedDB) {
      return false;
    }
    
    // Проверка, не находимся ли мы в приватном режиме
    // В Safari в приватном режиме indexedDB.open выбрасывает исключение
    const testDb = window.indexedDB.open('test');
    testDb.onerror = () => {
      console.warn('IndexedDB недоступен (возможно, используется приватный режим)');
    };
    
    return true;
  } catch (e) {
    console.error('Ошибка при проверке доступности IndexedDB:', e);
    return false;
  }
}

// Глобальная переменная для отслеживания статуса IndexedDB
export const dbStatus = {
  isAvailable: isIndexedDBAvailable(),
  error: null as Error | null,
  useLocalStorageFallback: false
};

export class GiftDatabase extends Dexie {
  /**
   * Таблица для хранения подарочных карт.
   * Ключ: `id` (string)
   */
  gifts!: Table<GiftCard, string>;

  /**
   * Таблица для хранения пользовательских сессий.
   * Ключ: `id` (string)
   */
  sessions!: Table<UserSession, string>;

  constructor() {
    super('GiftAdventureDB'); // Имя базы данных
    
    // Обработка ошибок при открытии базы данных
    this.on('blocked', () => {
      console.warn("База данных заблокирована другой вкладкой");
      dbStatus.error = new Error("База данных заблокирована");
    });
    
    this.on('versionchange', () => {
      console.warn("База данных изменена в другой вкладке");
      this.close();
    });
    
    this.version(1).stores({
      // Определение схемы и индексов для версии 1
      gifts:
        'id, title, *tags, rating, source, lastUpdated, available, partnerName, genderAffinity',
      // `id`: Первичный ключ
      // `title`: Индекс для поиска по названию
      // `*tags`: Multi-entry индекс для поиска по тегам
      // `rating`: Индекс для сортировки/фильтрации по рейтингу
      // `source`: Индекс для фильтрации по источнику ('manual' | 'api')
      // `lastUpdated`: Индекс для проверки актуальности
      // `available`: Индекс для фильтрации доступных подарков
      // `partnerName`: Индекс для фильтрации по партнеру
      // `genderAffinity`: Индекс для фильтрации/сортировки по гендерной принадлежности
    });

    // Добавляем таблицу sessions в версии 2 базы данных
    this.version(2).stores({
      sessions: 'id, createdAt, updatedAt, currentStep, isCompleted, isActive',
      // `id`: Первичный ключ (UUID)
      // `createdAt`: Индекс для сортировки по дате создания
      // `updatedAt`: Индекс для сортировки по дате обновления
      // `currentStep`: Индекс для фильтрации по текущему шагу
      // `isCompleted`: Индекс для фильтрации завершенных сессий
      // `isActive`: Индекс для быстрого доступа к активной сессии
    });
  }

  // Обертка для безопасного открытия базы данных
  async safeOpen(): Promise<boolean> {
    if (!dbStatus.isAvailable) {
      dbStatus.useLocalStorageFallback = true;
      return false;
    }

    try {
      await this.open();
      return true;
    } catch (error) {
      console.error("Ошибка при открытии базы данных:", error);
      dbStatus.error = error instanceof Error ? error : new Error("Неизвестная ошибка базы данных");
      dbStatus.useLocalStorageFallback = true;
      return false;
    }
  }
}

// Экспорт единственного экземпляра базы данных (Singleton)
export const db = new GiftDatabase();

// Fallback хранилище с localStorage
export class LocalStorageFallback {
  private prefix: string;

  constructor(prefix: string = 'gift_') {
    this.prefix = prefix;
  }

  async get<T>(key: string): Promise<T | undefined> {
    try {
      const item = localStorage.getItem(this.prefix + key);
      return item ? JSON.parse(item) as T : undefined;
    } catch (e) {
      console.error(`Ошибка при получении ${key} из localStorage:`, e);
      return undefined;
    }
  }

  async set<T>(key: string, value: T): Promise<void> {
    try {
      localStorage.setItem(this.prefix + key, JSON.stringify(value));
    } catch (e) {
      console.error(`Ошибка при сохранении ${key} в localStorage:`, e);
    }
  }

  async delete(key: string): Promise<void> {
    try {
      localStorage.removeItem(this.prefix + key);
    } catch (e) {
      console.error(`Ошибка при удалении ${key} из localStorage:`, e);
    }
  }

  async clear(): Promise<void> {
    try {
      // Удаляем только ключи с нашим префиксом
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key && key.startsWith(this.prefix)) {
          localStorage.removeItem(key);
        }
      }
    } catch (e) {
      console.error(`Ошибка при очистке localStorage:`, e);
    }
  }
}

export const localStorageDb = new LocalStorageFallback(); 