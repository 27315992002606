import { ReactNode, useEffect, useRef, useState } from 'react';
import { motion, Variants, AnimatePresence } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import gsap from 'gsap';
import { cn } from '@/shared/lib/utils';

export type TransitionType = 
  | 'fade' 
  | 'slide' 
  | 'scale' 
  | 'rotate' 
  | 'flip' 
  | 'zoom' 
  | 'blur' 
  | 'wipe' 
  | 'elastic'
  | 'bounce'
  | 'curl'
  | 'wave';

export type TransitionDirection = 
  | 'up' 
  | 'down' 
  | 'left' 
  | 'right'
  | 'center';

interface AdvancedTransitionProps {
  children: ReactNode;
  type?: TransitionType;
  direction?: TransitionDirection;
  duration?: number;
  delay?: number;
  ease?: string;
  exitBeforeEnter?: boolean;
  useCssOnly?: boolean;
  useGsap?: boolean;
  className?: string;
  enableFallback?: boolean;
  shouldAnimate?: boolean;
  onAnimationComplete?: () => void;
}

/**
 * Компонент для продвинутых анимаций переходов с поддержкой различных эффектов
 * Использует комбинацию Framer Motion и GSAP для оптимальной производительности
 * С автоматическим фоллбэком для устройств с низкой производительностью
 */
export function AdvancedTransition({
  children,
  type = 'fade',
  direction = 'up',
  duration = 0.5,
  delay = 0,
  ease = 'easeOut',
  exitBeforeEnter = true,
  useCssOnly = false,
  useGsap = false,
  className = '',
  enableFallback = true,
  shouldAnimate = true,
  onAnimationComplete,
}: AdvancedTransitionProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const [canAnimate, setCanAnimate] = useState<boolean>(true);
  const [key, setKey] = useState<string>(location.pathname);
  
  // Проверяем производительность устройства и включаем фоллбэк если нужно
  useEffect(() => {
    if (enableFallback) {
      // Простая эвристика для определения возможностей устройства
      const isLowPowered = window.navigator.hardwareConcurrency < 4;
      
      if (isLowPowered) {
        setCanAnimate(false);
      }
    }
  }, [enableFallback]);
  
  // Обновляем ключ при изменении маршрута для запуска новой анимации
  useEffect(() => {
    setKey(location.pathname);
  }, [location.pathname]);
  
  // Вариации для анимаций Framer Motion
  const getVariants = (): Variants => {
    switch (type) {
      case 'fade':
        return {
          initial: { opacity: 0 },
          animate: { opacity: 1, transition: { duration, delay, ease } },
          exit: { opacity: 0, transition: { duration: duration * 0.7, ease } },
        };
        
      case 'slide':
        const directionValues = {
          up: { y: 50 },
          down: { y: -50 },
          left: { x: 50 },
          right: { x: -50 },
          center: { y: 0, x: 0 },
        };
        
        return {
          initial: { opacity: 0, ...directionValues[direction] },
          animate: { 
            opacity: 1, 
            x: 0, 
            y: 0, 
            transition: { 
              duration, 
              delay, 
              ease,
            } 
          },
          exit: { 
            opacity: 0, 
            ...directionValues[direction],
            transition: { 
              duration: duration * 0.7, 
              ease,
            } 
          },
        };
        
      case 'scale':
        return {
          initial: { opacity: 0, scale: direction === 'up' ? 0.8 : 1.2 },
          animate: { 
            opacity: 1, 
            scale: 1, 
            transition: { 
              duration, 
              delay, 
              ease,
            }
          },
          exit: { 
            opacity: 0, 
            scale: direction === 'up' ? 1.2 : 0.8, 
            transition: { 
              duration: duration * 0.7, 
              ease,
            } 
          },
        };
        
      case 'rotate':
        return {
          initial: { 
            opacity: 0, 
            rotate: direction === 'left' ? 15 : -15,
            scale: 0.95,
          },
          animate: { 
            opacity: 1, 
            rotate: 0,
            scale: 1,
            transition: { 
              duration, 
              delay, 
              ease,
            }
          },
          exit: { 
            opacity: 0, 
            rotate: direction === 'left' ? -15 : 15,
            scale: 0.95,
            transition: { 
              duration: duration * 0.7, 
              ease,
            } 
          },
        };
        
      case 'flip':
        return {
          initial: { 
            opacity: 0, 
            rotateY: direction === 'left' || direction === 'right' 
              ? (direction === 'left' ? 90 : -90) 
              : 0,
            rotateX: direction === 'up' || direction === 'down' 
              ? (direction === 'up' ? -90 : 90) 
              : 0,
          },
          animate: { 
            opacity: 1, 
            rotateX: 0,
            rotateY: 0,
            transition: { 
              duration, 
              delay, 
              ease,
            }
          },
          exit: { 
            opacity: 0, 
            rotateY: direction === 'left' || direction === 'right' 
              ? (direction === 'left' ? -90 : 90) 
              : 0,
            rotateX: direction === 'up' || direction === 'down' 
              ? (direction === 'up' ? 90 : -90) 
              : 0,
            transition: { 
              duration: duration * 0.7, 
              ease,
            } 
          },
        };
        
      case 'zoom':
        return {
          initial: { opacity: 0, scale: 0.5 },
          animate: { 
            opacity: 1, 
            scale: 1,
            transition: { 
              type: 'spring',
              stiffness: 300,
              damping: 30,
              duration, 
              delay, 
            }
          },
          exit: { 
            opacity: 0, 
            scale: 0.5,
            transition: { 
              duration: duration * 0.7, 
              ease,
            } 
          },
        };

      case 'elastic':
        return {
          initial: { 
            opacity: 0, 
            y: direction === 'up' || direction === 'down' 
              ? (direction === 'up' ? 50 : -50) 
              : 0,
            x: direction === 'left' || direction === 'right' 
              ? (direction === 'left' ? 50 : -50) 
              : 0,
          },
          animate: { 
            opacity: 1, 
            y: 0,
            x: 0,
            transition: { 
              type: 'spring',
              stiffness: 400,
              damping: 17,
              duration, 
              delay, 
            }
          },
          exit: { 
            opacity: 0, 
            y: direction === 'up' || direction === 'down' 
              ? (direction === 'up' ? -50 : 50) 
              : 0,
            x: direction === 'left' || direction === 'right' 
              ? (direction === 'left' ? -50 : 50) 
              : 0,
            transition: { 
              duration: duration * 0.5, 
              ease,
            } 
          },
        };
        
      case 'bounce':
        return {
          initial: { 
            opacity: 0, 
            scale: 0.7,
            y: direction === 'up' ? 50 : -50
          },
          animate: { 
            opacity: 1, 
            scale: 1,
            y: 0,
            transition: { 
              type: 'spring',
              stiffness: 300,
              damping: 10,
              duration, 
              delay, 
            }
          },
          exit: { 
            opacity: 0, 
            scale: 0.7,
            y: direction === 'up' ? -50 : 50,
            transition: { 
              duration: duration * 0.7, 
              ease,
            } 
          },
        };

      case 'curl':
        return {
          initial: { 
            opacity: 0, 
            scale: 0.9,
            rotateX: direction === 'up' || direction === 'down' ? 45 : 0,
            rotateY: direction === 'left' || direction === 'right' ? 45 : 0,
          },
          animate: { 
            opacity: 1, 
            scale: 1,
            rotateX: 0,
            rotateY: 0,
            transition: { 
              duration, 
              delay, 
              ease,
            }
          },
          exit: { 
            opacity: 0, 
            scale: 0.9,
            rotateX: direction === 'up' || direction === 'down' ? 45 : 0,
            rotateY: direction === 'left' || direction === 'right' ? 45 : 0,
            transition: { 
              duration: duration * 0.7, 
              ease,
            } 
          },
        };

      case 'wave':
        return {
          initial: { 
            opacity: 0,
            rotate: -3,
            scale: 0.95,
          },
          animate: { 
            opacity: 1,
            rotate: 0,
            scale: 1,
            transition: {
              opacity: { duration: duration, delay },
              rotate: {
                duration: duration * 1.2,
                delay,
                ease: 'easeInOut',
                repeat: 0,
              },
              scale: { duration: duration, delay, ease },
            },
          },
          exit: { 
            opacity: 0,
            rotate: 3,
            scale: 0.95,
            transition: { 
              duration: duration * 0.7, 
              ease,
            },
          },
        };

      default:
        return {
          initial: { opacity: 0 },
          animate: { opacity: 1, transition: { duration, delay, ease } },
          exit: { opacity: 0, transition: { duration: duration * 0.7, ease } },
        };
    }
  };
  
  // Эффект для анимаций с использованием GSAP
  useEffect(() => {
    if (!useGsap || !shouldAnimate || !canAnimate || useCssOnly) return;
    
    const container = containerRef.current;
    if (!container) return;
    
    let animation: gsap.core.Tween | gsap.core.Timeline | undefined;
    
    switch (type) {
      case 'wipe':
        // Создаем оверлей для эффекта вайпа
        const overlay = document.createElement('div');
        overlay.className = 'gsap-wipe-overlay';
        overlay.style.position = 'absolute';
        overlay.style.top = '0';
        overlay.style.left = '0';
        overlay.style.width = '100%';
        overlay.style.height = '100%';
        overlay.style.backgroundColor = '#000';
        overlay.style.zIndex = '1000';
        overlay.style.pointerEvents = 'none';
        
        // Определяем направление вайпа
        const wipeFrom = direction === 'left' ? 'left' 
                        : direction === 'right' ? 'right'
                        : direction === 'up' ? 'bottom'
                        : direction === 'down' ? 'top'
                        : 'left';
        
        document.body.appendChild(overlay);
        
        container.style.opacity = '0';
        
        // Создаем анимацию
        const tl = gsap.timeline({ 
          onComplete: () => {
            gsap.to(container, { opacity: 1, duration: 0.1 });
            document.body.removeChild(overlay);
            if (onAnimationComplete) onAnimationComplete();
          }
        });
        
        // Анимация вайпа
        if (wipeFrom === 'left' || wipeFrom === 'right') {
          tl.fromTo(overlay, 
            { 
              width: '100%', 
              left: wipeFrom === 'left' ? '-100%' : '100%'
            },
            { 
              left: '0%', 
              duration: duration * 0.5, 
              ease
            }
          )
          .to(overlay, {
            left: wipeFrom === 'left' ? '100%' : '-100%',
            duration: duration * 0.5,
            ease,
          });
        } else {
          tl.fromTo(overlay, 
            { 
              height: '100%', 
              top: wipeFrom === 'bottom' ? '100%' : '-100%'
            },
            { 
              top: '0%', 
              duration: duration * 0.5, 
              ease
            }
          )
          .to(overlay, {
            top: wipeFrom === 'bottom' ? '-100%' : '100%',
            duration: duration * 0.5,
            ease,
          });
        }
        
        animation = tl;
        break;
        
      case 'blur':
        // Используем GSAP для создания эффекта размытия
        container.style.filter = 'blur(20px)';
        container.style.opacity = '0';
        
        animation = gsap.to(container, {
          filter: 'blur(0px)',
          opacity: 1,
          duration,
          delay,
          ease,
          onComplete: () => {
            if (onAnimationComplete) onAnimationComplete();
          }
        });
        break;
        
      default:
        // Для других типов можно использовать стандартную анимацию GSAP
        if (useGsap) {
          container.style.opacity = '0';
          
          animation = gsap.to(container, {
            opacity: 1,
            duration,
            delay,
            ease,
            onComplete: () => {
              if (onAnimationComplete) onAnimationComplete();
            }
          });
        }
        break;
    }
    
    return () => {
      if (animation) {
        animation.kill();
      }
    };
  }, [key, type, direction, duration, delay, ease, useGsap, shouldAnimate, canAnimate, useCssOnly, onAnimationComplete]);
  
  // Рендерим простой контейнер без анимаций если отключены
  if (!shouldAnimate || !canAnimate) {
    return (
      <div 
        ref={containerRef}
        className={cn("w-full h-full", className)}
      >
        {children}
      </div>
    );
  }
  
  // Если используем только GSAP, не применяем Framer Motion
  if (useGsap && (type === 'wipe' || type === 'blur') && !useCssOnly) {
    return (
      <div 
        ref={containerRef}
        className={cn("w-full h-full", className)}
        style={{ willChange: 'transform, opacity, filter' }}
      >
        {children}
      </div>
    );
  }
  
  // По умолчанию используем Framer Motion
  return (
    <AnimatePresence mode={exitBeforeEnter ? 'wait' : 'sync'}>
      <motion.div
        key={key}
        ref={containerRef}
        className={cn("w-full h-full", className)}
        style={{ 
          willChange: 'transform, opacity',
          perspective: type === 'flip' ? '1200px' : undefined,
        }}
        initial="initial"
        animate="animate"
        exit="exit"
        variants={getVariants()}
        onAnimationComplete={() => {
          if (onAnimationComplete) onAnimationComplete();
        }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
} 