import { ReactNode } from 'react';
import { cn } from '@/shared/lib/utils';
import { motion, HTMLMotionProps } from 'framer-motion';

export interface ButtonProps extends HTMLMotionProps<'button'> {
  variant?: 'primary' | 'secondary' | 'accent' | 'outline' | 'ghost';
  size?: 'sm' | 'md' | 'lg';
  isFullWidth?: boolean;
  isLoading?: boolean;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
}

export function Button({
  children,
  className,
  variant = 'primary',
  size = 'md',
  isFullWidth = false,
  isLoading = false,
  leftIcon,
  rightIcon,
  disabled,
  whileHover,
  whileTap,
  transition,
  ...props
}: ButtonProps) {
  // Базовые стили с улучшенным закруглением и переходами
  const baseStyles = 'group inline-flex items-center justify-center rounded-xl font-medium transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none';
  
  // Улучшенные стили вариантов с градиентами и тенями
  const variantStyles = {
    primary: 'bg-gradient-to-r from-primary-500 to-primary-600 text-white hover:from-primary-600 hover:to-primary-700 focus:ring-primary-500 shadow-lg hover:shadow-xl shadow-primary-500/20 border border-primary-400 hover:border-primary-500',
    secondary: 'bg-gradient-to-r from-secondary-500 to-secondary-600 text-white hover:from-secondary-600 hover:to-secondary-700 focus:ring-secondary-500 shadow-lg hover:shadow-xl shadow-secondary-500/20 border border-secondary-400 hover:border-secondary-500',
    accent: 'bg-gradient-to-r from-accent-500 to-accent-600 text-white hover:from-accent-600 hover:to-accent-700 focus:ring-accent-500 shadow-lg hover:shadow-xl shadow-accent-500/20 border border-accent-400 hover:border-accent-500',
    outline: 'bg-white/80 backdrop-blur-sm border-2 border-slate-300 text-slate-700 hover:bg-slate-50 hover:border-slate-400 focus:ring-slate-400 shadow-md hover:shadow-lg shadow-slate-300/10',
    ghost: 'bg-transparent text-slate-700 hover:bg-slate-100/80 hover:backdrop-blur-sm hover:text-slate-900 focus:ring-slate-500 hover:shadow-sm',
  };
  
  // Улучшенные размеры кнопок
  const sizeStyles = {
    sm: 'text-sm px-3.5 py-1.5',
    md: 'text-base px-5 py-2.5',
    lg: 'text-lg px-7 py-3.5',
  };
  
  const fullWidthStyles = isFullWidth ? 'w-full' : '';

  // Улучшенные анимации
  const defaultTransition = {
    type: 'spring',
    stiffness: 500,
    damping: 15,
  };

  const defaultHoverAnimation = { 
    scale: 1.03, 
    y: -2,
    boxShadow: '0 10px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)' 
  };
  
  const defaultTapAnimation = { 
    scale: 0.97,
    y: 1
  };
  
  return (
    <motion.button
      className={cn(
        baseStyles,
        variantStyles[variant],
        sizeStyles[size],
        fullWidthStyles,
        className
      )}
      disabled={disabled || isLoading}
      whileHover={whileHover || defaultHoverAnimation}
      whileTap={whileTap || defaultTapAnimation}
      transition={transition || defaultTransition}
      {...props}
    >
      {isLoading && (
        <svg 
          className="mr-2 h-4 w-4 animate-spin"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle 
            className="opacity-25" 
            cx="12" 
            cy="12" 
            r="10" 
            stroke="currentColor" 
            strokeWidth="4"
          />
          <path 
            className="opacity-75" 
            fill="currentColor" 
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      )}
      
      {!isLoading && leftIcon && (
        <div className="mr-2 transition-transform duration-300 group-hover:scale-110">
          {leftIcon}
        </div>
      )}
      
      <span className="relative">
        {variant !== 'ghost' && variant !== 'outline' && (
          <span className="absolute inset-0 bg-white/10 opacity-0 rounded-lg blur-md group-hover:opacity-100 transition-opacity duration-300" />
        )}
        {children}
      </span>
      
      {!isLoading && rightIcon && (
        <div className="ml-2 transition-transform duration-300 group-hover:scale-110">
          {rightIcon}
        </div>
      )}
    </motion.button>
  );
}