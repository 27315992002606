import { GiftTag } from '@/shared/types/gift.types';

// Тип данных для формы подбора подарков
export interface GiftFormValues {
  gender?: 'мужской' | 'женский' | 'любой';
  age?: [number, number];
  priceRange?: [number, number];
  relationship?: string;
  occasion?: string;
  interests?: string[];
  personality?: string[];
}

export interface HolidayPreset {
  id: string;
  name: string;
  description: string;
  iconEmoji: string;
  date: Date | { day: number; month: number } | null;
  dateDescription?: string;
  isUpcoming?: boolean;
  colors: {
    primary: string;
    secondary: string;
    accent?: string;
  };
  gender?: 'мужской' | 'женский' | 'любой';
  age?: [number, number];
  priceRange?: [number, number];
  relationship?: string;
  occasion?: string;
  interests?: string[];
  personality?: string[];
  seoKeywords: string[];
  urlPath: string;
  contentUrl?: string;
}

// Интерфейс для словаря праздников
export interface HolidayPresets {
  [key: string]: HolidayPreset;
}

// Предопределенные праздники
export const HOLIDAY_PRESETS: HolidayPresets = {
  '8-march': {
    id: '8-march',
    name: '8 марта',
    description: 'Найдите идеальный подарок для женщин к Международному женскому дню',
    iconEmoji: '🌷',
    date: { day: 8, month: 3 },
    isUpcoming: true,
    gender: 'женский',
    age: [18, 65],
    priceRange: [1000, 5000],
    relationship: 'любая',
    occasion: 'праздник',
    interests: ['красота', 'мода', 'уют'],
    personality: ['общительный', 'творческий', 'романтичный'],
    colors: {
      primary: 'pink',
      secondary: 'purple',
      accent: 'rose'
    },
    seoKeywords: ['подарок на 8 марта', 'женский день', 'подарки женщинам', 'что подарить на 8 марта'],
    urlPath: '/holiday/8-march'
  },
  'new-year': {
    id: 'new-year',
    name: 'Новый год',
    description: 'Лучшие идеи подарков к Новому году для всех возрастов',
    iconEmoji: '🎄',
    date: { day: 31, month: 12 },
    gender: 'любой',
    age: [1, 100],
    priceRange: [500, 10000],
    relationship: 'любая',
    occasion: 'праздник',
    interests: ['развлечения', 'дом', 'хобби'],
    personality: ['любой'],
    colors: {
      primary: 'green',
      secondary: 'red',
      accent: 'blue'
    },
    seoKeywords: ['новогодние подарки', 'подарки на новый год', 'что подарить на новый год', 'идеи новогодних подарков'],
    urlPath: '/holiday/new-year'
  },
  'valentines-day': {
    id: 'valentines-day',
    name: 'День святого Валентина',
    description: 'Романтические идеи подарков для вашей второй половинки',
    iconEmoji: '❤️',
    date: { day: 14, month: 2 },
    gender: 'любой',
    age: [16, 65],
    priceRange: [1000, 7000],
    relationship: 'романтические',
    occasion: 'праздник',
    interests: ['романтика', 'развлечения', 'красота'],
    personality: ['романтичный', 'творческий'],
    colors: {
      primary: 'red',
      secondary: 'pink',
      accent: 'rose'
    },
    seoKeywords: ['подарок на день валентина', 'романтический подарок', 'подарок любимым', 'день влюбленных'],
    urlPath: '/holiday/valentines-day'
  },
  'birthday': {
    id: 'birthday',
    name: 'День рождения',
    description: 'Универсальные идеи для подарков на день рождения',
    iconEmoji: '🎂',
    date: null, // День рождения не имеет фиксированной даты
    gender: 'любой',
    age: [1, 100],
    priceRange: [500, 10000],
    relationship: 'любая',
    occasion: 'день рождения',
    interests: ['хобби', 'развлечения', 'технологии'],
    personality: ['любой'],
    colors: {
      primary: 'blue',
      secondary: 'yellow',
      accent: 'amber'
    },
    seoKeywords: ['подарки на день рождения', 'идеи подарков на др', 'что подарить на день рождения', 'оригинальные подарки'],
    urlPath: '/holiday/birthday'
  },
  '23-february': {
    id: '23-february',
    name: '23 февраля',
    description: 'Подарки для мужчин к Дню защитника Отечества',
    iconEmoji: '🎖️',
    date: { day: 23, month: 2 },
    gender: 'мужской',
    age: [18, 70],
    priceRange: [1000, 7000],
    relationship: 'любая',
    occasion: 'праздник',
    interests: ['технологии', 'спорт', 'хобби', 'отдых'],
    personality: ['практичный', 'активный'],
    colors: {
      primary: 'blue',
      secondary: 'gray',
      accent: 'indigo'
    },
    seoKeywords: ['подарки на 23 февраля', 'подарки мужчинам', 'день защитника отечества', 'что подарить мужчине'],
    urlPath: '/holiday/23-february'
  }
};

/**
 * Получает ближайший праздник из списка предустановленных
 */
export function getUpcomingHoliday(): HolidayPreset | null {
  const now = new Date();
  const currentMonth = now.getMonth() + 1; // Месяцы в JS начинаются с 0
  const currentDay = now.getDate();
  
  let closestHoliday: HolidayPreset | null = null;
  let minDaysDiff = 366; // Максимально возможное количество дней в году
  
  Object.values(HOLIDAY_PRESETS).forEach(holiday => {
    if (!holiday.date || typeof holiday.date === 'object' && 'day' in holiday.date) {
      const holidayDate = typeof holiday.date === 'object' && 'day' in holiday.date 
        ? holiday.date 
        : null;
      
      if (holidayDate) {
        // Вычисляем разницу в днях до праздника
        let targetMonth = holidayDate.month;
        let targetDay = holidayDate.day;
        
        // Вычисляем дату в текущем году
        let holidayThisYear = new Date(now.getFullYear(), targetMonth - 1, targetDay);
        
        // Если праздник в этом году уже прошел, смотрим на следующий год
        if (holidayThisYear < now) {
          holidayThisYear = new Date(now.getFullYear() + 1, targetMonth - 1, targetDay);
        }
        
        // Разница в миллисекундах
        const diffMs = holidayThisYear.getTime() - now.getTime();
        // Конвертируем в дни
        const diffDays = Math.ceil(diffMs / (1000 * 60 * 60 * 24));
        
        if (diffDays < minDaysDiff) {
          minDaysDiff = diffDays;
          closestHoliday = holiday;
        }
      }
    }
  });
  
  return closestHoliday;
}

/**
 * Получает несколько ближайших праздников
 */
export function getUpcomingHolidays(count: number = 3): HolidayPreset[] {
  const now = new Date();
  const currentYear = now.getFullYear();
  
  // Копируем пресеты в массив для сортировки
  const holidaysArray = Object.values(HOLIDAY_PRESETS);
  
  // Добавляем даты на этот год
  const holidaysWithDates = holidaysArray.map(holiday => {
    if (holiday.date && typeof holiday.date === 'object' && 'day' in holiday.date) {
      const { month, day } = holiday.date;
      let holidayDate = new Date(currentYear, month - 1, day);
      
      // Если праздник в этом году уже прошел, берем дату на следующий год
      if (holidayDate < now) {
        holidayDate = new Date(currentYear + 1, month - 1, day);
      }
      
      return {
        ...holiday,
        nextDate: holidayDate
      };
    }
    
    // Если точная дата неизвестна, просто ставим далекую дату
    return {
      ...holiday,
      nextDate: new Date(currentYear + 1, 11, 31) // 31 декабря следующего года
    };
  });
  
  // Сортируем по дате (от ближайшей к дальней)
  holidaysWithDates.sort((a, b) => a.nextDate.getTime() - b.nextDate.getTime());
  
  // Помечаем первые праздники как ближайшие
  const result = holidaysWithDates.slice(0, count).map((holiday, index) => ({
    ...holiday,
    isUpcoming: true
  }));
  
  // Возвращаем первые `count` праздников без поля nextDate
  return result.map(({ nextDate, ...holiday }) => holiday);
} 