import { useState, useEffect } from 'react';

// Характеристики устройства и браузера
export interface DeviceCapabilities {
  // Общая оценка производительности
  performanceTier: 'low' | 'medium' | 'high';
  
  // Аппаратные возможности
  cpuCores: number;
  memory: number | null; // В МБ, null если не определено
  
  // Возможности дисплея
  screenWidth: number;
  screenHeight: number;
  pixelRatio: number;
  hasTouch: boolean;
  
  // Возможности анимаций
  supportsAdvancedAnimations: boolean;
  prefersReducedMotion: boolean;
  
  // Прочие характеристики
  connectionType: string | null; // Тип подключения (4g, wifi, etc.)
  isBatteryLow: boolean | null; // Низкий заряд батареи
  isMobile: boolean;
}

/**
 * Определяет общую производительность устройства
 * на основе комбинации различных факторов
 */
export function detectPerformanceTier(): 'low' | 'medium' | 'high' {
  // Определение количества ядер CPU
  const cpuCores = navigator.hardwareConcurrency || 1;
  
  // Проверка на мобильное устройство
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  
  // Проверка на новый или старый браузер
  const browserAge = detectBrowserAge();
  
  // Определение производительности дисплея
  const hasHighResDensity = window.devicePixelRatio > 2;
  const hasLargeScreen = window.innerWidth >= 1280 && window.innerHeight >= 720;
  
  // Определяем уровень производительности на основе множества факторов
  if (
    (cpuCores >= 8 && !isMobile && browserAge === 'new' && hasLargeScreen) ||
    (cpuCores >= 6 && !isMobile && browserAge === 'new')
  ) {
    return 'high';
  } else if (
    (cpuCores >= 4 && !hasHighResDensity) ||
    (cpuCores >= 2 && !isMobile && browserAge !== 'old')
  ) {
    return 'medium';
  } else {
    return 'low';
  }
}

/**
 * Определяет "возраст" браузера на основе поддержки
 * современных web API и функций
 */
function detectBrowserAge(): 'new' | 'medium' | 'old' {
  // Проверяем поддержку современных API
  const supportsWebAnimations = 'animate' in document.createElement('div');
  const supportsWebShare = 'share' in navigator;
  const supportsWebP = (document as any).createElement('canvas')
    .toDataURL('image/webp')
    .indexOf('data:image/webp') === 0;
  
  if (supportsWebAnimations && supportsWebShare && supportsWebP) {
    return 'new';
  } else if (supportsWebAnimations || supportsWebP) {
    return 'medium';
  } else {
    return 'old';
  }
}

/**
 * Определяет тип соединения пользователя
 */
export function detectConnectionType(): string | null {
  // @ts-ignore - TS не знает о типе navigator.connection
  const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
  
  if (connection && connection.effectiveType) {
    return connection.effectiveType; // slow-2g, 2g, 3g, 4g
  }
  
  return null;
}

/**
 * Хук для получения информации о возможностях устройства
 */
export function useDeviceCapabilities(): DeviceCapabilities {
  const [capabilities, setCapabilities] = useState<DeviceCapabilities>({
    performanceTier: 'medium', // По умолчанию предполагаем среднюю производительность
    cpuCores: navigator.hardwareConcurrency || 2,
    memory: null,
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight,
    pixelRatio: window.devicePixelRatio,
    hasTouch: 'ontouchstart' in window,
    supportsAdvancedAnimations: true,
    prefersReducedMotion: false,
    connectionType: null,
    isBatteryLow: null,
    isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
  });
  
  useEffect(() => {
    // Определяем производительность при монтировании компонента
    const performanceTier = detectPerformanceTier();
    const connectionType = detectConnectionType();
    
    // Проверяем предпочтения пользователя по сокращению анимаций
    const prefersReducedMotion = window.matchMedia?.('(prefers-reduced-motion: reduce)').matches || false;
    
    // Поддержка продвинутых анимаций на основе производительности и предпочтений
    const supportsAdvancedAnimations = 
      performanceTier !== 'low' && 
      !prefersReducedMotion;
    
    // Проверяем состояние батареи, если API доступно
    let isBatteryLow: boolean | null = null;
    
    if ('getBattery' in navigator) {
      (navigator as any).getBattery().then((battery: any) => {
        isBatteryLow = battery.level < 0.2 && !battery.charging;
        
        // Обновляем состояние при получении информации о батарее
        setCapabilities(prev => ({
          ...prev,
          isBatteryLow,
        }));
        
        // Слушаем изменения уровня заряда батареи
        battery.addEventListener('levelchange', () => {
          isBatteryLow = battery.level < 0.2 && !battery.charging;
          setCapabilities(prev => ({
            ...prev,
            isBatteryLow,
          }));
        });
      });
    }
    
    // Обновляем состояние
    setCapabilities(prev => ({
      ...prev,
      performanceTier,
      supportsAdvancedAnimations,
      prefersReducedMotion,
      connectionType,
    }));
    
    // Слушаем изменения размера экрана
    const handleResize = () => {
      setCapabilities(prev => ({
        ...prev,
        screenWidth: window.innerWidth,
        screenHeight: window.innerHeight,
      }));
    };
    
    // Слушаем изменения настроек медиа
    const reducedMotionMediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
    const handleReducedMotionChange = (e: MediaQueryListEvent) => {
      setCapabilities(prev => {
        const newPrefersReducedMotion = e.matches;
        return {
          ...prev,
          prefersReducedMotion: newPrefersReducedMotion,
          supportsAdvancedAnimations: prev.performanceTier !== 'low' && !newPrefersReducedMotion,
        };
      });
    };
    
    window.addEventListener('resize', handleResize);
    reducedMotionMediaQuery.addEventListener('change', handleReducedMotionChange);
    
    return () => {
      window.removeEventListener('resize', handleResize);
      reducedMotionMediaQuery.removeEventListener('change', handleReducedMotionChange);
    };
  }, []);
  
  return capabilities;
}

/**
 * Хук для интеллектуального снижения сложности анимаций
 * в зависимости от возможностей устройства
 */
export function useAdaptiveAnimations(
  defaultSettings: {
    duration?: number;
    complexity?: 'simple' | 'normal' | 'complex';
    enableParticles?: boolean;
    enableBlur?: boolean;
  } = {}
) {
  const deviceCapabilities = useDeviceCapabilities();
  
  // Настройки анимаций на основе возможностей устройства
  const adaptedSettings = {
    // Продолжительность анимаций
    duration: (() => {
      // Базовая продолжительность из настроек или по умолчанию
      const baseDuration = defaultSettings.duration ?? 0.5;
      
      if (deviceCapabilities.prefersReducedMotion) {
        return baseDuration * 0.5; // Сокращаем время для пользователей с предпочтением сокращения движения
      }
      
      if (deviceCapabilities.performanceTier === 'low') {
        return baseDuration * 0.8; // Немного ускоряем для слабых устройств
      }
      
      return baseDuration;
    })(),
    
    // Сложность анимаций
    complexity: (() => {
      // Если пользователь предпочитает уменьшенное движение, всегда используем простые анимации
      if (deviceCapabilities.prefersReducedMotion) {
        return 'simple';
      }
      
      // Адаптируем сложность на основе производительности
      switch (deviceCapabilities.performanceTier) {
        case 'high':
          return defaultSettings.complexity ?? 'complex';
        case 'medium':
          return (defaultSettings.complexity === 'simple') ? 'simple' : 'normal';
        case 'low':
          return 'simple'; // Всегда простые анимации для низкой производительности
        default:
          return 'simple';
      }
    })(),
    
    // Включение/отключение сложных эффектов
    enableParticles: (() => {
      if (deviceCapabilities.prefersReducedMotion) return false;
      
      if (deviceCapabilities.performanceTier === 'low') {
        return false;
      }
      
      if (deviceCapabilities.isBatteryLow) {
        return false;
      }
      
      return defaultSettings.enableParticles ?? true;
    })(),
    
    enableBlur: (() => {
      if (deviceCapabilities.prefersReducedMotion) return false;
      
      if (deviceCapabilities.performanceTier === 'low') {
        return false;
      }
      
      return defaultSettings.enableBlur ?? true;
    })(),
    
    // Возможности устройства (проброс для использования в компонентах)
    deviceCapabilities,
  };
  
  return adaptedSettings;
} 