import { Howl, HowlOptions } from 'howler';

export type AudioTrack = {
  id: string;
  url: string;
  volume?: number;
  loop?: boolean;
  autoplay?: boolean;
  preload?: boolean;
  mute?: boolean;
};

export type AudioCategory = 'music' | 'sfx' | 'voice' | 'ambient';

/**
 * Класс для управления звуком в приложении с использованием Howler.js
 */
export class AudioPlayer {
  private static instance: AudioPlayer;
  private sounds: Map<string, Howl> = new Map();
  private categories: Map<AudioCategory, Set<string>> = new Map();
  private isMuted: boolean = false;
  private masterVolume: number = 1;
  private categoryVolumes: Map<AudioCategory, number> = new Map();
  
  private constructor() {
    // Уменьшаем максимальное количество экземпляров аудио для каждого звука
    Howler.html5PoolSize = 10; // Значение по умолчанию: 10
    
    // Инициализируем категории
    this.categories.set('music', new Set());
    this.categories.set('sfx', new Set());
    this.categories.set('voice', new Set());
    this.categories.set('ambient', new Set());
    
    // Устанавливаем громкость по умолчанию для каждой категории
    this.categoryVolumes.set('music', 0.7);
    this.categoryVolumes.set('sfx', 1.0);
    this.categoryVolumes.set('voice', 1.0);
    this.categoryVolumes.set('ambient', 0.5);
  }
  
  /**
   * Получить единственный экземпляр AudioPlayer (Singleton)
   */
  public static getInstance(): AudioPlayer {
    if (!AudioPlayer.instance) {
      AudioPlayer.instance = new AudioPlayer();
    }
    return AudioPlayer.instance;
  }
  
  /**
   * Загрузить звуковой трек
   * @param track Конфигурация звукового трека
   * @param category Категория трека
   * @returns ID загруженного трека
   */
  public load(track: AudioTrack, category: AudioCategory = 'sfx'): string {
    if (this.sounds.has(track.id)) {
      return track.id;
    }
    
    const options: HowlOptions = {
      src: [track.url],
      volume: track.volume ?? this.categoryVolumes.get(category) ?? 1,
      loop: track.loop ?? false,
      autoplay: track.autoplay ?? false,
      preload: track.preload ?? true,
      mute: (track.mute ?? false) || this.isMuted,
      html5: true, // Использовать HTML5 Audio API для поддержки мобильных устройств
    };
    
    const sound = new Howl(options);
    this.sounds.set(track.id, sound);
    
    // Добавляем трек в соответствующую категорию
    const categorySet = this.categories.get(category);
    if (categorySet) {
      categorySet.add(track.id);
    }
    
    return track.id;
  }
  
  /**
   * Воспроизвести звук
   * @param id ID звукового трека
   * @returns Идентификатор воспроизведения или null в случае ошибки
   */
  public play(id: string): number | null {
    const sound = this.sounds.get(id);
    if (!sound) {
      console.warn(`Звук с ID ${id} не найден`);
      return null;
    }
    
    // Проверяем состояние AudioContext и пытаемся восстановить его
    if (Howler.ctx && Howler.ctx.state !== 'running') {
      console.log("Trying to resume AudioContext before playing sound");
      Howler.ctx.resume().then(() => {
        console.log("AudioContext resumed, playing sound");
        return sound.play();
      }).catch(err => {
        console.error("Failed to resume AudioContext:", err);
        return null;
      });
      return null; // Return null for this async path
    } else {
      return sound.play();
    }
  }
  
  /**
   * Остановить воспроизведение звука
   * @param id ID звукового трека
   */
  public stop(id: string, playId?: number): void {
    const sound = this.sounds.get(id);
    if (!sound) {
      console.warn(`Звук с ID ${id} не найден`);
      return;
    }
    
    if (playId !== undefined) {
      sound.stop(playId);
    } else {
      sound.stop();
    }
  }
  
  /**
   * Приостановить воспроизведение звука
   * @param id ID звукового трека
   */
  public pause(id: string, playId?: number): void {
    const sound = this.sounds.get(id);
    if (!sound) {
      console.warn(`Звук с ID ${id} не найден`);
      return;
    }
    
    if (playId !== undefined) {
      sound.pause(playId);
    } else {
      sound.pause();
    }
  }
  
  /**
   * Установить общую громкость для всех звуков
   * @param volume Значение громкости от 0 до 1
   */
  public setMasterVolume(volume: number): void {
    this.masterVolume = Math.max(0, Math.min(1, volume));
    Howler.volume(this.masterVolume);
  }
  
  /**
   * Установить громкость для конкретной категории звуков
   * @param category Категория звуков
   * @param volume Значение громкости от 0 до 1
   */
  public setCategoryVolume(category: AudioCategory, volume: number): void {
    const normalizedVolume = Math.max(0, Math.min(1, volume));
    this.categoryVolumes.set(category, normalizedVolume);
    
    // Обновляем громкость для всех звуков в категории
    const categoryTracks = this.categories.get(category);
    if (categoryTracks) {
      categoryTracks.forEach(id => {
        const sound = this.sounds.get(id);
        if (sound) {
          sound.volume(normalizedVolume * this.masterVolume);
        }
      });
    }
  }
  
  /**
   * Включить/выключить все звуки
   * @param muted true - выключить, false - включить
   */
  public mute(muted: boolean): void {
    this.isMuted = muted;
    Howler.mute(muted);
  }
  
  /**
   * Включить/выключить звуки определенной категории
   * @param category Категория звуков
   * @param muted true - выключить, false - включить
   */
  public muteCategory(category: AudioCategory, muted: boolean): void {
    const categoryTracks = this.categories.get(category);
    if (categoryTracks) {
      categoryTracks.forEach(id => {
        const sound = this.sounds.get(id);
        if (sound) {
          sound.mute(muted);
        }
      });
    }
  }
  
  /**
   * Выгрузить звук из памяти
   * @param id ID звукового трека
   */
  public unload(id: string): void {
    const sound = this.sounds.get(id);
    if (sound) {
      sound.unload();
      this.sounds.delete(id);
      
      // Удаляем трек из всех категорий
      for (const [_, tracks] of this.categories) {
        tracks.delete(id);
      }
    }
  }
  
  /**
   * Проверить, загружен ли звук
   * @param id ID звукового трека
   * @returns true, если звук загружен
   */
  public isLoaded(id: string): boolean {
    const sound = this.sounds.get(id);
    return sound ? sound.state() === 'loaded' : false;
  }
  
  /**
   * Проверить, воспроизводится ли звук
   * @param id ID звукового трека
   * @returns true, если звук воспроизводится
   */
  public isPlaying(id: string): boolean {
    const sound = this.sounds.get(id);
    return sound ? sound.playing() : false;
  }
  
  /**
   * Остановить все звуки
   */
  public stopAll(): void {
    Howler.stop();
  }
  
  /**
   * Выгрузить все звуки
   */
  public unloadAll(): void {
    this.sounds.forEach(sound => {
      sound.unload();
    });
    this.sounds.clear();
    
    // Очищаем все категории
    this.categories.forEach(tracks => {
      tracks.clear();
    });
  }
} 