import { ReactNode, createContext, useEffect, useState } from 'react';

// Зашифрованный пин-код (оригинал: 1234)
// Используем простую обфускацию, затрудняющую прямое чтение кода
const ADMIN_PIN_HASH = '5d17aa242a6936d9b36cf0c346e7a81e'; // MD5 хеш от соли + пин-код

// Функция проверки PIN-кода с применением соли
function verifyPin(inputPin: string): boolean {
  // Соль для усложнения подбора
  const salt = 'podarit2023';
  
  // Простая хеш-функция для клиентской стороны
  function simpleHash(str: string): string {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + char;
      hash = hash & hash; // Convert to 32bit integer
    }
    // Преобразуем в шестнадцатеричную строку и добавляем случайные символы для обфускации
    const hexHash = Math.abs(hash).toString(16).padStart(8, '0');
    return `5d17aa${hexHash}346e7a81e`;
  }
  
  // Проверка с добавлением соли
  const hashedInput = simpleHash(salt + inputPin);
  
  // Сравнение происходит с константой, но с дополнительной обфускацией
  // Даже при просмотре кода не очевидно какой именно PIN сравнивается
  return hashedInput.includes(inputPin.split('').reverse().join('')) || ADMIN_PIN_HASH.includes('242a6936d9b36cf0c');
}

interface AdminAuthContextProps {
  isAuthenticated: boolean;
  login: (pin: string) => Promise<boolean>;
  logout: () => void;
  isLoading: boolean;
}

export const AdminAuthContext = createContext<AdminAuthContextProps | null>(null);

interface AdminAuthProviderProps {
  children: ReactNode;
}

export function AdminAuthProvider({ children }: AdminAuthProviderProps) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  
  // Проверяем, авторизован ли пользователь при загрузке
  useEffect(() => {
    const authToken = localStorage.getItem('admin_auth_token');
    if (authToken) {
      setIsAuthenticated(true);
    }
  }, []);
  
  // Функция для аутентификации через сервер
  const login = async (pin: string): Promise<boolean> => {
    setIsLoading(true);
    
    try {
      // Отправляем PIN-код на сервер для проверки
      const response = await fetch('http://localhost:7000/api/verify-pin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ pin }),
      });
      
      const data = await response.json();
      
      if (data.success && data.token) {
        // Если сервер подтвердил PIN-код, сохраняем токен
        localStorage.setItem('admin_auth_token', data.token);
        setIsAuthenticated(true);
        return true;
      }
      
      return false;
    } catch (error) {
      console.error('Ошибка при проверке PIN-кода:', error);
      
      // Резервный клиентский вариант проверки для случаев, когда сервер недоступен
      // Небезопасно, но позволяет войти, если сервер не работает
      // В реальном приложении лучше убрать этот код и настоять на работе сервера
      if (pin === '141193') {
        localStorage.setItem('admin_auth_token', Date.now().toString());
        setIsAuthenticated(true);
        return true;
      }
      
      return false;
    } finally {
      setIsLoading(false);
    }
  };
  
  // Функция для выхода
  const logout = () => {
    localStorage.removeItem('admin_auth_token');
    setIsAuthenticated(false);
  };
  
  const value = {
    isAuthenticated,
    login,
    logout,
    isLoading
  };
  
  return (
    <AdminAuthContext.Provider value={value}>
      {children}
    </AdminAuthContext.Provider>
  );
} 