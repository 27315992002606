import React, { ComponentType, useCallback } from 'react';
import { useUiSound } from './AudioContext';

export type SoundEffectEvent = 'onClick' | 'onMouseEnter' | 'onMouseLeave' | 'onFocus' | 'onBlur' | 'onSelect' | 'onChange';

export interface SoundEffectsConfig {
  onClick?: 'click' | 'select' | 'toggle' | false;
  onMouseEnter?: 'click' | 'select' | 'toggle' | false;
  onMouseLeave?: 'click' | 'select' | 'toggle' | false;
  onFocus?: 'click' | 'select' | 'toggle' | false;
  onBlur?: 'click' | 'select' | 'toggle' | false;
  onSelect?: 'click' | 'select' | 'toggle' | false;
  onChange?: 'click' | 'select' | 'toggle' | false;
}

// Значения по умолчанию для разных типов компонентов
const DEFAULT_CONFIGURATIONS: Record<string, SoundEffectsConfig> = {
  button: {
    onClick: 'click',
    onMouseEnter: false,
  },
  checkbox: {
    onClick: 'toggle',
    onMouseEnter: 'click',
  },
  radio: {
    onClick: 'select',
    onMouseEnter: 'click',
  },
  select: {
    onChange: 'select',
    onFocus: 'click',
  },
  input: {
    onFocus: 'click',
    onBlur: 'click',
  },
  link: {
    onClick: 'click',
    onMouseEnter: 'click',
  },
};

/**
 * HOC для добавления звуковых эффектов к компонентам
 * @param WrappedComponent Компонент, который нужно обернуть
 * @param config Конфигурация звуковых эффектов или имя предустановленной конфигурации
 */
export function withSoundEffects<P extends object>(
  WrappedComponent: ComponentType<P>,
  config: SoundEffectsConfig | keyof typeof DEFAULT_CONFIGURATIONS = 'button'
): ComponentType<P> {
  const effectsConfig = typeof config === 'string' ? DEFAULT_CONFIGURATIONS[config] : config;
  
  const WithSoundEffects = (props: P) => {
    const { playClickSound, playSelectSound, playToggleSound } = useUiSound();
    
    // Функция для выбора соответствующего звукового эффекта
    const playSoundEffect = useCallback((effectType: 'click' | 'select' | 'toggle' | false) => {
      if (effectType === false) return;
      
      switch (effectType) {
        case 'click':
          playClickSound();
          break;
        case 'select':
          playSelectSound();
          break;
        case 'toggle':
          playToggleSound();
          break;
      }
    }, [playClickSound, playSelectSound, playToggleSound]);
    
    // Создаем улучшенные обработчики событий с добавлением звуков
    const enhancedEventHandlers = Object.entries(effectsConfig).reduce<Record<string, Function>>(
      (handlers, [event, soundEffect]) => {
        const originalHandler = props[event as keyof P] as Function | undefined;
        
        handlers[event] = (...args: any[]) => {
          // Воспроизводим звук
          playSoundEffect(soundEffect);
          
          // Вызываем оригинальный обработчик, если он есть
          if (originalHandler) {
            return originalHandler(...args);
          }
        };
        
        return handlers;
      },
      {}
    );
    
    // Объединяем оригинальные пропсы с улучшенными обработчиками событий
    const enhancedProps = {
      ...props,
      ...enhancedEventHandlers,
    };
    
    return <WrappedComponent {...enhancedProps as P} />;
  };
  
  // Имя для отладки
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
  WithSoundEffects.displayName = `WithSoundEffects(${displayName})`;
  
  return WithSoundEffects;
}

/**
 * Создает кнопку со звуковыми эффектами на основе переданного компонента кнопки
 * @param ButtonComponent Компонент кнопки
 */
export function createSoundButton<P extends object>(ButtonComponent: ComponentType<P>) {
  return withSoundEffects(ButtonComponent, 'button');
}

/**
 * Создает чекбокс со звуковыми эффектами на основе переданного компонента чекбокса
 * @param CheckboxComponent Компонент чекбокса
 */
export function createSoundCheckbox<P extends object>(CheckboxComponent: ComponentType<P>) {
  return withSoundEffects(CheckboxComponent, 'checkbox');
}

/**
 * Создает радио-кнопку со звуковыми эффектами на основе переданного компонента радио-кнопки
 * @param RadioComponent Компонент радио-кнопки
 */
export function createSoundRadio<P extends object>(RadioComponent: ComponentType<P>) {
  return withSoundEffects(RadioComponent, 'radio');
}

/**
 * Создает селект со звуковыми эффектами на основе переданного компонента селекта
 * @param SelectComponent Компонент селекта
 */
export function createSoundSelect<P extends object>(SelectComponent: ComponentType<P>) {
  return withSoundEffects(SelectComponent, 'select');
}

/**
 * Создает поле ввода со звуковыми эффектами на основе переданного компонента поля ввода
 * @param InputComponent Компонент поля ввода
 */
export function createSoundInput<P extends object>(InputComponent: ComponentType<P>) {
  return withSoundEffects(InputComponent, 'input');
}

/**
 * Создает ссылку со звуковыми эффектами на основе переданного компонента ссылки
 * @param LinkComponent Компонент ссылки
 */
export function createSoundLink<P extends object>(LinkComponent: ComponentType<P>) {
  return withSoundEffects(LinkComponent, 'link');
} 