import {
  CompanionCharacter,
  CompanionAnimation,
  CompanionExpression,
  CompanionPhrase,
  CompanionEvent,
  CompanionEventType
} from './types';

/**
 * Базовый путь к анимациям персонажа
 */
export const COMPANION_ANIMATIONS_PATH = '/lottie/companion/';

/**
 * Базовый путь к изображениям персонажа
 */
export const COMPANION_IMAGES_PATH = '/images/companion/';

/**
 * Время бездействия после которого персонаж скрывается (мс)
 */
export const IDLE_TIMEOUT = 60000; // 1 минута

/**
 * Данные персонажа-компаньона по умолчанию
 */
export const DEFAULT_COMPANION: CompanionCharacter = {
  id: 'fairy',
  name: 'Фея Луми',
  description: 'Волшебная фея, которая поможет подобрать идеальный подарок для ваших близких',
  defaultImageUrl: `/images/lumy-fairy.png`,
  expressions: [
    {
      type: 'neutral',
      assetPath: `/images/lumy-fairy.png`,
      assetType: 'image'
    },
    {
      type: 'happy',
      assetPath: `/images/lumy-fairy.png`,
      assetType: 'image'
    },
    {
      type: 'thinking',
      assetPath: `/images/lumy-fairy.png`,
      assetType: 'image'
    },
    {
      type: 'confused',
      assetPath: `/images/lumy-fairy.png`,
      assetType: 'image'
    },
    {
      type: 'excited',
      assetPath: `/images/lumy-fairy.png`,
      assetType: 'image'
    },
    {
      type: 'sad',
      assetPath: `/images/lumy-fairy.png`,
      assetType: 'image'
    },
    {
      type: 'surprised',
      assetPath: `/images/lumy-fairy.png`,
      assetType: 'image'
    }
  ],
  animations: [
    {
      type: 'idle',
      lottiePath: null,
      loop: true
    },
    {
      type: 'wave',
      lottiePath: null,
      duration: 2000,
      loop: false
    },
    {
      type: 'speak',
      lottiePath: null,
      loop: true
    },
    {
      type: 'jump',
      lottiePath: null,
      duration: 1000,
      loop: false
    },
    {
      type: 'blink',
      lottiePath: null,
      duration: 500,
      loop: false
    },
    {
      type: 'point',
      lottiePath: null,
      duration: 1500,
      loop: false
    },
    {
      type: 'nod',
      lottiePath: null,
      duration: 1000,
      loop: false
    },
    {
      type: 'shake',
      lottiePath: null,
      duration: 1000,
      loop: false
    }
  ]
};

/**
 * Фразы, которые может говорить персонаж
 */
export const COMPANION_PHRASES: CompanionPhrase[] = [
  // Приветственные фразы
  {
    id: 'greeting_1',
    text: 'Привет! Я Луми, твоя помощница в выборе подарка!',
    expression: 'happy',
    animation: 'wave',
    tags: ['greeting']
  },
  {
    id: 'greeting_2',
    text: 'Добро пожаловать в мир волшебных подарков! Я Луми, и я помогу тебе выбрать идеальный подарок!',
    expression: 'excited',
    animation: 'wave',
    tags: ['greeting', 'welcome']
  },
  {
    id: 'greeting_3',
    text: 'Давай вместе найдем подарок, который принесет настоящую радость!',
    expression: 'happy',
    animation: 'point',
    tags: ['greeting', 'introduction']
  },
  
  // Фразы для шага выбора персонажа
  {
    id: 'character_step_1',
    text: 'Для кого ты выбираешь подарок? Выбери тип отношений, это поможет мне лучше понять, что искать!',
    expression: 'neutral',
    animation: 'point',
    tags: ['character', 'step', 'help']
  },
  {
    id: 'character_selected_1',
    text: 'Отлично! Теперь я знаю, для кого мы ищем волшебный подарок!',
    expression: 'happy',
    animation: 'nod',
    tags: ['character', 'selected', 'confirmation']
  },
  {
    id: 'character_selected_partner',
    text: 'Подарок для любимого человека — особая магия! Я помогу выбрать что-то по-настоящему чудесное!',
    expression: 'excited',
    animation: 'speak',
    tags: ['character', 'selected', 'partner']
  },
  {
    id: 'character_selected_friend',
    text: 'Друзья заслуживают подарков, которые говорят "Я тебя ценю"! Найдем что-то особенное!',
    expression: 'happy',
    animation: 'speak',
    tags: ['character', 'selected', 'friend']
  },
  {
    id: 'character_selected_family',
    text: 'Семья — это наше всё! Вместе мы подберем подарок, который тронет сердце!',
    expression: 'happy',
    animation: 'speak',
    tags: ['character', 'selected', 'family']
  },
  {
    id: 'character_selected_colleague',
    text: 'Для коллеги важно выбрать что-то со вкусом и подходящее к ситуации. Справимся!',
    expression: 'thinking',
    animation: 'speak',
    tags: ['character', 'selected', 'colleague']
  },
  {
    id: 'character_selected_self',
    text: 'Себя тоже нужно радовать! Давай найдем что-то, что подарит тебе настоящее волшебство!',
    expression: 'excited',
    animation: 'speak',
    tags: ['character', 'selected', 'self']
  },
  
  // Фразы для шага выбора возраста
  {
    id: 'age_step_1',
    text: 'А теперь давай определимся с возрастом. Каждый возраст имеет свою магию!',
    expression: 'neutral',
    animation: 'point',
    tags: ['age', 'step', 'help']
  },
  {
    id: 'age_selected_kid',
    text: 'Дети обожают волшебство! Подберем что-то яркое и вдохновляющее!',
    expression: 'excited',
    animation: 'jump',
    tags: ['age', 'selected', 'kid']
  },
  {
    id: 'age_selected_teen',
    text: 'Подросткам нравятся современные и крутые вещи! У меня есть несколько волшебных идей!',
    expression: 'happy',
    animation: 'speak',
    tags: ['age', 'selected', 'teen']
  },
  {
    id: 'age_selected_adult',
    text: 'Взрослым понравятся подарки, сочетающие практичность и приятные эмоции!',
    expression: 'neutral',
    animation: 'nod',
    tags: ['age', 'selected', 'adult']
  },
  {
    id: 'age_selected_senior',
    text: 'Старшему поколению особенно приятны подарки с душой и смыслом. Найдем что-то особенное!',
    expression: 'thinking',
    animation: 'speak',
    tags: ['age', 'selected', 'senior']
  },
  
  // Фразы для шага выбора бюджета
  {
    id: 'budget_step_1',
    text: 'Теперь поговорим о бюджете. Волшебные подарки можно найти в любой ценовой категории!',
    expression: 'neutral',
    animation: 'point',
    tags: ['budget', 'step', 'help']
  },
  {
    id: 'budget_selected_low',
    text: 'Самые душевные подарки не всегда дорогие! Главное — вложенные чувства и внимание!',
    expression: 'happy',
    animation: 'nod',
    tags: ['budget', 'selected', 'low']
  },
  {
    id: 'budget_selected_medium',
    text: 'Отличный выбор! С таким бюджетом у нас есть множество волшебных вариантов!',
    expression: 'happy',
    animation: 'speak',
    tags: ['budget', 'selected', 'medium']
  },
  {
    id: 'budget_selected_high',
    text: 'Ух ты! С таким бюджетом мы можем создать настоящее чудо! Давай удивим на полную!',
    expression: 'excited',
    animation: 'jump',
    tags: ['budget', 'selected', 'high']
  },
  
  // Фразы для шага выбора интересов
  {
    id: 'interests_step_1',
    text: 'Какие интересы у получателя подарка? Это ключик к его сердцу!',
    expression: 'neutral',
    animation: 'point',
    tags: ['interests', 'step', 'help']
  },
  {
    id: 'interest_selected_1',
    text: 'Прекрасный выбор! Я уже придумываю волшебные идеи для подарка!',
    expression: 'happy',
    animation: 'nod',
    tags: ['interest', 'selected', 'confirmation']
  },
  {
    id: 'interest_deselected_1',
    text: 'Понятно! Учтем, что это не совсем подходит, и поищем что-то другое!',
    expression: 'neutral',
    animation: 'nod',
    tags: ['interest', 'deselected', 'confirmation']
  },
  {
    id: 'interests_many_selected',
    text: 'Сколько интересов! Это открывает огромный мир возможностей для подарков!',
    expression: 'excited',
    animation: 'jump',
    tags: ['interests', 'many', 'selected']
  },
  {
    id: 'interests_few_selected',
    text: 'Может, добавим ещё какие-то интересы? Чем больше деталей, тем волшебнее будет подарок!',
    expression: 'thinking',
    animation: 'speak',
    tags: ['interests', 'few', 'selected', 'hint']
  },
  
  // Фразы для шага уточнения предпочтений
  {
    id: 'preferences_step_1',
    text: 'Уточним предпочтения для идеального подарка. Каждая деталь важна для волшебства!',
    expression: 'neutral',
    animation: 'point',
    tags: ['preferences', 'step', 'help']
  },
  {
    id: 'slider_changed_practicality',
    text: 'Отмечаю важность практичности! Это поможет мне создать правильное волшебство!',
    expression: 'neutral',
    animation: 'nod',
    tags: ['slider', 'changed', 'practicality']
  },
  {
    id: 'slider_changed_uniqueness',
    text: 'Уникальность — это важно! Отмечаю, насколько необычным должен быть подарок!',
    expression: 'happy',
    animation: 'nod',
    tags: ['slider', 'changed', 'uniqueness']
  },
  {
    id: 'slider_changed_sentimentality',
    text: 'Чувства и эмоции — это основа волшебства подарков! Я учту твой выбор!',
    expression: 'happy',
    animation: 'speak',
    tags: ['slider', 'changed', 'sentimentality']
  },
  
  // Фразы для шага отображения результатов
  {
    id: 'results_step_1',
    text: 'Вот подарки, которые я выбрала специально для тебя! Надеюсь, они принесут настоящую радость!',
    expression: 'excited',
    animation: 'point',
    tags: ['results', 'step', 'help']
  },
  {
    id: 'results_many',
    text: 'Посмотри, сколько волшебных вариантов! Уверена, среди них есть идеальный подарок!',
    expression: 'excited',
    animation: 'jump',
    tags: ['results', 'many', 'options']
  },
  {
    id: 'results_few',
    text: 'Вот несколько чудесных вариантов! Если хочешь больше, можем вернуться и изменить наши заклинания поиска!',
    expression: 'thinking',
    animation: 'speak',
    tags: ['results', 'few', 'options', 'hint']
  },
  {
    id: 'results_none',
    text: 'Ой! Кажется, моя магия не смогла найти подходящие подарки. Давай изменим параметры и попробуем снова?',
    expression: 'sad',
    animation: 'shake',
    tags: ['results', 'none', 'options', 'hint']
  },
  
  // Общие фразы для разных ситуаций
  {
    id: 'hint_general_1',
    text: 'Не можешь решить? Я всегда рядом, чтобы помочь своим волшебством!',
    expression: 'happy',
    animation: 'wave',
    tags: ['hint', 'general', 'help']
  },
  {
    id: 'idle_1',
    text: 'Я здесь, готова помочь своей магией подарков в любой момент!',
    expression: 'neutral',
    animation: 'wave',
    tags: ['idle', 'hint', 'help']
  },
  {
    id: 'idle_2',
    text: 'Думаешь? Это хорошо! Лучшие решения приходят, когда мы даем себе время подумать!',
    expression: 'thinking',
    animation: 'speak',
    tags: ['idle', 'hint']
  },
  {
    id: 'error_occurred_1',
    text: 'Ой! Моя магия дала сбой! Давай попробуем наколдовать всё заново?',
    expression: 'confused',
    animation: 'shake',
    tags: ['error', 'help']
  },
  {
    id: 'session_restored_1',
    text: 'Я сохранила всё наше волшебство! Можем продолжить с того места, где остановились!',
    expression: 'happy',
    animation: 'jump',
    tags: ['session', 'restored', 'help']
  }
];

/**
 * События, на которые может реагировать персонаж
 */
export const COMPANION_EVENTS: Record<CompanionEventType, CompanionEvent> = {
  step_change: {
    type: 'step_change',
    phraseIds: ['hint_general_1'],
    expression: 'neutral',
    animation: 'speak',
    priority: 5
  },
  character_selected: {
    type: 'character_selected',
    phraseIds: ['character_selected_1'],
    expression: 'happy',
    animation: 'nod',
    priority: 7
  },
  age_selected: {
    type: 'age_selected',
    phraseIds: ['age_selected_adult'], // Конкретная фраза будет выбрана в зависимости от возраста
    expression: 'happy',
    animation: 'nod',
    priority: 7
  },
  budget_selected: {
    type: 'budget_selected',
    phraseIds: ['budget_selected_medium'], // Конкретная фраза будет выбрана в зависимости от бюджета
    expression: 'happy',
    animation: 'nod',
    priority: 7
  },
  interest_selected: {
    type: 'interest_selected',
    phraseIds: ['interest_selected_1'],
    expression: 'happy',
    animation: 'nod',
    priority: 6
  },
  interest_deselected: {
    type: 'interest_deselected',
    phraseIds: ['interest_deselected_1'],
    expression: 'neutral',
    animation: 'nod',
    priority: 6
  },
  slider_changed: {
    type: 'slider_changed',
    phraseIds: ['slider_changed_practicality'], // Конкретная фраза будет выбрана в зависимости от слайдера
    expression: 'neutral',
    animation: 'nod',
    priority: 4
  },
  gift_shown: {
    type: 'gift_shown',
    phraseIds: ['results_many'], // Конкретная фраза будет выбрана в зависимости от количества подарков
    expression: 'excited',
    animation: 'point',
    priority: 8
  },
  idle_timeout: {
    type: 'idle_timeout',
    phraseIds: ['idle_1', 'idle_2'],
    expression: 'neutral',
    animation: 'wave',
    priority: 1
  },
  session_restored: {
    type: 'session_restored',
    phraseIds: ['session_restored_1'],
    expression: 'happy',
    animation: 'jump',
    priority: 9
  },
  error_occurred: {
    type: 'error_occurred',
    phraseIds: ['error_occurred_1'],
    expression: 'confused',
    animation: 'shake',
    priority: 10
  }
};

/**
 * Маппинг шагов выбора подарка к фразам компаньона
 */
export const STEP_TO_PHRASES_MAP: Record<string, string[]> = {
  character: ['character_step_1', 'greeting_1', 'greeting_2', 'greeting_3'],
  age: ['age_step_1'],
  budget: ['budget_step_1'],
  interests: ['interests_step_1'],
  preferences: ['preferences_step_1'],
  results: ['results_step_1']
}; 